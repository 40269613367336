import { DateTime } from 'luxon'
import { BiCopy, BiLinkExternal } from 'react-icons/bi'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import {
  Button,
  ButtonFlat,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../../../../../components'
import { CloneReportModal } from '../../../../../../../../../../components/molecules/modals/cloneReportModal/CloneReportModal'
import { useModal } from '../../../../../../../../../../hooks/useModal'
import { useRelatedReports, useReportStatus } from '../../../../../../../../../../modules/reports/hooks/useReports'
import { routes } from '../../../../../../../../../../services/Routing'

const ModalContent = styled.div`
  padding: 24px;
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
`

const ModalHeaderText = styled(Text)`
  margin-bottom: 24px;
`

const ModalActions = styled.nav`
  align-self: flex-end;
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

const ActionsTableCell = styled(TableCell)`
  display: flex;
  gap: 12px;
`

export const RelatedReportsModal = ({ onClose }) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: relatedReports } = useRelatedReports(reportId)
  const { data: status } = useReportStatus(reportId)
  const theme = useTheme()
  const [{ isOpen, params }, { showModal: showConfirmationModal, closeModal: closeConfirmationModal }] = useModal()

  const handleOpenRelatedReport = (data) => {
    const newURL = window.location.href.replace(reportId, data.reportId)
    window.open(newURL, '_blank', 'noopener,noreferrer')
  }

  const handleCloneRelatedReport = (data) => {
    showConfirmationModal(data)
  }

  const handleCloneReport = () => {
    if (!params.reportId) return

    navigate(generatePath(routes.reportClonation, { reportId }), { replace: true, state: { from: params.reportId } })
  }

  const { isClosed } = status ?? {}
  return (
    <ModalContent>
      <ModalHeaderText size="xl" weight="light" variant="ntrl_dark">
        Expedientes relacionados
      </ModalHeaderText>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Text variant="black" weight="500" size="sm">
                Nº de siniestro
              </Text>
            </TableCell>

            <TableCell>
              <Text variant="black" weight="500" size="sm">
                Expediente
              </Text>
            </TableCell>

            <TableCell>
              <Text variant="black" weight="500" size="sm">
                Fecha de cierre
              </Text>
            </TableCell>

            <TableCell>
              <Text variant="black" weight="500" size="sm">
                Acciones
              </Text>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {relatedReports?.map((report) => (
            <TableRow key={report.id}>
              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  {report.numero_siniestro}
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  {report.expediente}
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  {DateTime.fromISO(report.fecha_cierre).toFormat('dd-MM-yyyy')}
                </Text>
              </TableCell>

              <ActionsTableCell>
                <ButtonFlat
                  variant="info"
                  title="Abrir expediente"
                  onClick={() => handleOpenRelatedReport({ reportId: report.id })}
                >
                  <BiLinkExternal size={18} color={theme?.colors?.white?.main} />
                  Abrir
                </ButtonFlat>

                {!isClosed ? (
                  <ButtonFlat
                    variant="negative"
                    title="Reutilizar expediente"
                    onClick={() => handleCloneRelatedReport({ reportId: report.id })}
                  >
                    <BiCopy size={18} color={theme?.colors?.white?.main} />
                    Reutilizar
                  </ButtonFlat>
                ) : null}
              </ActionsTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ModalActions>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalActions>

      <CloneReportModal isOpen={isOpen} onCancel={closeConfirmationModal} onConfirm={handleCloneReport} />
    </ModalContent>
  )
}
