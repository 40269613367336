import { generatePath, Outlet, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text } from '../../../../../../components'
import Logo from '../../../../../../components/atoms/logo/Logo'
import { Header } from '../../../../../../components/molecules/header/Header'
import { StatusBar } from '../../../../../../components/molecules/statusBar/StatusBar'
import { withDownloadModal } from '../../../../../../hooks/useDownload'
import { useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useTarifas } from '../../../../../../modules/tarifas/hooks/useTarifas'
import { routes } from '../../../../../../services/Routing'
import Utils from '../../../../../../services/Utils'
import { DEVICE_SIZE } from '../../../../../../theme/theme'
import { Sidebar } from './components/sidebar/Sidebar'

const Container = styled.div`
  width: 100%;
  height: calc(100% - 24px);
  padding-top: 60px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    flex-direction: column;
  }
`

const Content = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  border-left: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    width: 100%;
    border-left: none;
  }
`

const ContentWrapper = styled.article`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
  width: 100%;
`

const CommentsWrapper = styled.div``

function generateSidebarItems({ reportId, tipoExpediente }) {
  const MAP_TIPO_EXPEDIENTE_SIDEBAR = {
    [Utils.TIPO_EXPEDIENTE.DANOS]: [
      {
        label: 'Información general',
        path: generatePath(routes.informacionGeneral, { reportId }),
      },
      {
        label: 'Destino del riesgo',
        path: generatePath(routes.destinoDelRiesgo, { reportId }),
      },
      {
        label: 'Tipo de siniestro',
        path: generatePath(routes.tipoDeSiniestro, { reportId }),
      },
      {
        label: 'Medidas de seguridad',
        path: generatePath(routes.medidasDeSeguridad, { reportId }),
      },
      {
        label: 'Análisis de riesgo',
        path: generatePath(routes.analisisDeRiesgo, { reportId }),
      },
      {
        label: 'Responsables',
        path: generatePath(routes.responsables, { reportId }),
      },
      {
        label: 'Perjudicados',
        path: generatePath(routes.perjudicados, { reportId }),
      },
      {
        label: 'Descripción del siniestro',
        path: generatePath(routes.descripcion, { reportId }),
      },
      {
        label: 'Análisis de capitales',
        path: generatePath(routes.analisisDeCapitales, { reportId }),
      },
      {
        label: 'Valoración',
        path: generatePath(routes.valoracion, { reportId }),
      },
      {
        label: 'Propuesta final',
        path: generatePath(routes.propuestaFinal, { reportId }),
      },
      {
        label: 'Anexos',
        path: generatePath(routes.anexos, { reportId }),
      },
      // {
      //   label: 'Factura',
      //   path: generatePath(routes.factura, { reportId }),
      // },
    ],
    [Utils.TIPO_EXPEDIENTE.DEF_JURIDICA]: [
      {
        label: 'Información general',
        path: generatePath(routes.informacionGeneral, { reportId }),
      },
      {
        label: 'Responsables',
        path: generatePath(routes.responsables, { reportId }),
      },
      {
        label: 'Textos del informe',
        path: generatePath(routes.descripcion, { reportId }),
      },
      {
        label: 'Valoración',
        path: generatePath(routes.valoracion, { reportId }),
      },
      {
        label: 'Anexos',
        path: generatePath(routes.anexos, { reportId }),
      },
    ],
  }

  return MAP_TIPO_EXPEDIENTE_SIDEBAR[tipoExpediente] ?? MAP_TIPO_EXPEDIENTE_SIDEBAR[Utils.TIPO_EXPEDIENTE.DANOS]
}

export const CompanyRegularCreationReportLayout = withDownloadModal(({ handleDownload }) => {
  const { reportId } = useParams()
  const { data: report, isLoading: isReportLoading } = useStoredReport(reportId)
  useTarifas()

  return (
    <Container>
      <Header />

      <Sidebar items={generateSidebarItems({ reportId, tipoExpediente: report?.tipoExpediente })} />

      <Content>
        <ContentWrapper>
          {isReportLoading ? (
            <Loader>
              <Logo />
              <Text variant="black">Sincronizando datos del informe...</Text>
            </Loader>
          ) : (
            <Outlet />
          )}
          <CommentsWrapper id="comments-content" />
        </ContentWrapper>
      </Content>
      <StatusBar />
    </Container>
  )
})
