import React from 'react'
import { BiCopy } from 'react-icons/bi'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { ButtonFlat, Text } from '../../../components'
import Card from '../../../components/atoms/card/Card'
import Tag from '../../../components/atoms/tag/Tag'
import { HighlightedBlock } from '../../../components/molecules/highlightedBlock/HighlightedBlock'
import { CloneReportModal } from '../../../components/molecules/modals/cloneReportModal/CloneReportModal'
import { useModal } from '../../../hooks/useModal'
import { useRelatedReports, useReportById, useReportStatus } from '../../../modules/reports/hooks/useReports'
import { routes } from '../../../services/Routing'
import { Headline } from '../../dashboard/creation/components/headline'

const Tags = styled.div`
  display: flex;
  gap: 12px;
  margin: 12px 0 30px;
`

const ReportInformation = styled(HighlightedBlock)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 12px;
`

const InformationBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const FieldTitle = styled(Text)`
  line-height: initial;
`

const RelatedReportsBlock = styled.div`
  margin-top: 60px;
`

const RelatedReports = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 320px));
  gap: 12px;
`

const FIELDS = [
  {
    title: 'Fecha de creación',
    value: 'created',
  },
  {
    title: 'Última modificación',
    value: 'fecha_ultima_actualizacion',
  },
  {
    title: 'Fecha de cierre',
    value: 'fecha_cierre',
  },
  {
    title: 'Fecha de encargo',
    value: 'fecha_encargo',
  },
  {
    title: 'Fecha efecto',
    value: 'fecha_efecto',
  },
  {
    title: 'Estado',
    value: 'estado_id',
  },
  {
    title: 'Perito',
    value: 'interviniente_id',
  },
  {
    title: 'Compañía',
    value: 'compania_id',
  },
  {
    title: 'Tipo de peritación',
    value: 'tipo_peritacion',
  },
  {
    title: 'Asegurado',
    value: 'asegurado',
  },
  {
    title: 'Oficina',
    value: 'oficina',
  },
  {
    title: 'Poliza',
    value: 'poliza',
  },
  {
    title: 'Lugar',
    value: 'lugar',
  },
]

export const ReportDetailsScreen = () => {
  const navigate = useNavigate()
  const theme = useTheme()
  const { reportId } = useParams()
  const { data: report, isLoading: isLoadingReport } = useReportById(reportId)
  const { data: relatedReports, isLoading: isLoadingRelatedReports } = useRelatedReports(reportId)
  const { data: status, isLoading: isLoadingStatus } = useReportStatus(reportId)
  const [{ isOpen, params }, { showModal: showConfirmationModal, closeModal: closeConfirmationModal }] = useModal()

  const handleCloneRelatedReport = (data) => {
    showConfirmationModal(data)
  }

  const handleCloneReport = () => {
    if (!params.reportId) return
    navigate(generatePath(routes.reportClonation, { reportId }), { replace: true, state: { from: params.reportId } })
  }

  if (isLoadingReport || isLoadingRelatedReports || isLoadingStatus) {
    return null
  }

  const { isClosed } = status ?? {}
  return (
    <>
      <Headline size="l" weight="500" variant="black">
        {`Expediente ${report?.numero_siniestro}`}
      </Headline>

      <Tags>
        <Tag variant="primary">{report?.estado}</Tag>
      </Tags>

      <ReportInformation>
        {FIELDS.map(({ title, value }) => (
          <InformationBlock key={title}>
            <FieldTitle size="sm" variant="ntrl_darkest">
              {title}
            </FieldTitle>

            <Text size="m" weight="600" variant="ntrl_darkest">
              {report?.[value]}
            </Text>
          </InformationBlock>
        ))}
      </ReportInformation>

      <RelatedReportsBlock id="related">
        <Headline size="l" weight="500" variant="black">
          Expedientes relacionados
        </Headline>

        <RelatedReports>
          {relatedReports?.map((report) => (
            <Card
              key={report.id}
              onClick={() => {
                window.open(generatePath(routes.home, { reportId: report.id }), '_blank', 'noopener,noreferrer')
              }}
            >
              <Card.Title>{report?.numero_siniestro}</Card.Title>

              <Card.Body size="m" weight="600" variant="ntrl_darkest">
                {report?.numero_siniestro}
              </Card.Body>

              {!isClosed ? (
                <Card.Actions>
                  <ButtonFlat
                    variant="negative"
                    title="Reutilizar expediente"
                    onClick={(evt) => {
                      evt.preventDefault()
                      evt.stopPropagation()
                      handleCloneRelatedReport({ reportId: report.id })
                    }}
                  >
                    <BiCopy size={18} color={theme?.colors?.white?.main} />
                    Reutilizar
                  </ButtonFlat>
                </Card.Actions>
              ) : null}
            </Card>
          ))}

          {relatedReports?.length === 0 ? (
            <Card key={report.id}>
              <Card.Title>Sin expedientes</Card.Title>

              <Card.Body size="m" weight="600" variant="ntrl_darkest">
                No existen expedientes relacionados para este informe
              </Card.Body>
            </Card>
          ) : null}
        </RelatedReports>
      </RelatedReportsBlock>

      <CloneReportModal isOpen={isOpen} onCancel={closeConfirmationModal} onConfirm={handleCloneReport} />
    </>
  )
}
