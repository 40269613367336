import React from 'react'
import { useForm } from 'react-hook-form'
import { BiLinkExternal } from 'react-icons/bi'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { Button, ButtonFlat, Radio, Text } from '../../../../components'
import Card from '../../../../components/atoms/card/Card'
import { useRelatedReports } from '../../../../modules/reports/hooks/useReports'
import { routes } from '../../../../services/Routing'
import { Headline } from '../components/headline'

const Container = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
`

const Content = styled.div`
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

const Inputs = styled.div`
  margin: 60px 0;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 320px));
  width: 100%;
`
const Actions = styled.nav`
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

const StyledRadio = styled(Radio)`
  position: absolute;
  top: 15px;
  left: 12px;
`

const RelatedReportCard = styled(Card)`
  position: relative;
  padding: 12px;
`

const RelatedReportCardTitle = styled(Card.Title)`
  margin-left: 30px;
`

export const CopyReportScreen = React.memo(() => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      cloneFrom: '',
    },
    mode: 'onBlur',
  })
  const { data: relatedReports } = useRelatedReports(reportId)

  const handleCancelClick = () => {
    navigate(generatePath(routes.informacionGeneral, { reportId }))
  }

  const onSubmit = (values) => {
    navigate(generatePath(routes.reportClonation, { reportId }), { replace: true, state: { from: values.cloneFrom } })
  }

  const { isSubmitting } = formState
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Content>
        <Headline size="l" weight="500" variant="black">
          ¡Un momento!
        </Headline>
        <Text variant="black" align="center">
          Hemos detectado que estas intentando generar un expediente ampliatorio. ¿Quieres copiar los datos de uno de
          los expedientes previos?
        </Text>

        <Inputs>
          {relatedReports?.map((report) => (
            <RelatedReportCard
              key={report.id}
              onClick={() => {
                setValue('cloneFrom', report.id.toString())
              }}
            >
              <StyledRadio value={report.id} {...register('cloneFrom')} />
              <RelatedReportCardTitle>{report.numero_siniestro}</RelatedReportCardTitle>
              <Card.Body>
                Creado el {new Date(report.created).toLocaleDateString()} a las{' '}
                {new Date(report.created).toLocaleTimeString()}
              </Card.Body>

              <Card.Actions>
                <ButtonFlat
                  as="a"
                  variant="info"
                  rel="noopener noreferrer"
                  target="_blank"
                  href={generatePath(routes.home, { reportId: report.id })}
                  title="Abrir expediente"
                >
                  <BiLinkExternal size={18} color={theme?.colors?.white?.main} />
                  Abrir
                </ButtonFlat>
              </Card.Actions>
            </RelatedReportCard>
          ))}
        </Inputs>
        <Actions>
          <Button bordered type="button" variant="ntrl_dark" onClick={handleCancelClick} disabled={isSubmitting}>
            Saltar este paso
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Copiando informe...' : 'Sí, copiar del informe seleccionado'}
          </Button>
        </Actions>
      </Content>
    </Container>
  )
})
