import React, { useCallback } from 'react'
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineFolderAdd } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import {
  Button,
  Dropdown,
  DropdownOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../../components'
import { useIsClosed, useStoredReport } from '../../../../../../../modules/reports/hooks/useReports'
import Utils from '../../../../../../../services/Utils'

const StyledTableRow = styled(TableRow)`
  background-color: ${({ theme }) => `${theme?.colors?.ntrl_lighter?.main}`};
`

const TotalTableRow = styled(TableRow)`
  & td {
    padding: 10px 20px;
  }
`

const StyledTableCell = styled(TableCell)`
  padding: 10px 20px;
`

const PerjudicadoTableCell = styled(TableCell)`
  padding: 3px 20px;
`

const StyledDropdown = styled(Dropdown)`
  top: 60px;
  right: 20px;
  z-index: 99;
`

const StyledDropdownOption = styled(DropdownOption)`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ isDangerAction }) =>
    isDangerAction &&
    css`
      color: ${({ theme }) => theme.colors.negative.main};
    `}
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

export const ValoracionDanosTable = ({ valoraciones, onAdd, onRemove }) => {
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const theme = useTheme()

  const renderItem = useCallback(
    (item) => {
      const idx = valoraciones.findIndex((i) => i.id === item.id)
      return (
        <TableRow key={item.id}>
          <TableCell>
            <Text variant="black" size="sm">
              {item.Id}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.Descripcion}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.RepInd}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.Unidades}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.CosteUnidad}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.ValorNuevo}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.ValorReal}
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" size="sm">
              {item.TotalPropuesta}
            </Text>
          </TableCell>

          <TableCell>
            <StyledDropdown>
              <StyledDropdownOption onClick={() => onAdd?.({ initialData: item, isEditionMode: true, idx })}>
                <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                Editar
              </StyledDropdownOption>
              <StyledDropdownOption onClick={() => onAdd?.({ initialData: { ...item } })}>
                <AiOutlineCopy color={theme?.colors?.ntrl_dark?.main} size={20} />
                Duplicar
              </StyledDropdownOption>
              <StyledDropdownOption isDangerAction onClick={() => onRemove?.(idx)}>
                <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                Eliminar
              </StyledDropdownOption>
            </StyledDropdown>
          </TableCell>
        </TableRow>
      )
    },
    [valoraciones, theme?.colors?.ntrl_dark?.main, theme?.colors?.negative?.main, onAdd, onRemove],
  )

  const renderTotalRow = useCallback((data) => {
    const { unidades, valorNuevo, valorReal, total } = data.reduce(
      (summary, item) => {
        summary.unidades += parseInt(item.Unidades)
        summary.valorNuevo = summary.valorNuevo.add(Utils.toDinero(parseFloat(item.ValorNuevo)))
        summary.valorReal = summary.valorReal.add(Utils.toDinero(parseFloat(item.ValorReal)))
        summary.total = summary.total.add(Utils.toDinero(parseFloat(item.TotalPropuesta)))
        return summary
      },
      { unidades: 0, valorNuevo: Utils.toDinero(0), valorReal: Utils.toDinero(0), total: Utils.toDinero(0) },
    )
    return (
      <TotalTableRow>
        <TableCell>
          <Text variant="black" size="sm" weight="600">
            Resumen
          </Text>
        </TableCell>

        <TableCell></TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {unidades}
          </Text>
        </TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {valorNuevo.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {valorReal.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {total.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell></TableCell>
      </TotalTableRow>
    )
  }, [])

  const rows = valoraciones ?? []
  const propios = rows.filter((item) => item.TipoDanio === 'propio')
  const esteticos = rows.filter((item) => item.TipoDanio === 'estetico')
  const perjudicados = rows
    .filter((item) => item.TipoDanio === 'perjudicados')
    .reduce((perjudicados, item) => {
      if (!perjudicados[item.Perjudicado]) {
        perjudicados[item.Perjudicado] = []
      }
      perjudicados[item.Perjudicado].push(item)
      return perjudicados
    }, {})

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Epígrafe
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Descripción
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Rep.Ind.
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Unidades
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Coste Ud.
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              V. Nuevo
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              V. Real
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Total
            </Text>
          </TableCell>

          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {propios.length > 0 && (
          <>
            <StyledTableRow>
              <StyledTableCell colSpan="9">
                <Text variant="black" weight="600" size="sm">
                  Daños propios
                </Text>
              </StyledTableCell>
            </StyledTableRow>

            {propios.map(renderItem)}

            {renderTotalRow(propios)}
          </>
        )}

        {esteticos.length > 0 && (
          <>
            <StyledTableRow>
              <StyledTableCell colSpan="9">
                <Text variant="black" weight="600" size="sm">
                  Daños estéticos
                </Text>
              </StyledTableCell>
            </StyledTableRow>

            {esteticos.map(renderItem)}

            {renderTotalRow(esteticos)}
          </>
        )}

        {Object.keys(perjudicados).length > 0 && (
          <>
            <StyledTableRow>
              <StyledTableCell colSpan="9">
                <Text variant="black" weight="600" size="sm">
                  Daños a perjudicados
                </Text>
              </StyledTableCell>
            </StyledTableRow>
            {Object.keys(perjudicados).map((perjudicadoId, idx) => {
              const rows = perjudicados[perjudicadoId]
              const perjudicado = report.perjudicados.find((p) => p.perjudicados_id === perjudicadoId)
              return (
                <React.Fragment key={perjudicadoId}>
                  <StyledTableRow>
                    <PerjudicadoTableCell colSpan="9">
                      <Text variant="black" weight="600" size="sm">
                        {`Perjudicado nº ${idx + 1} - ${perjudicado?.perjudicados_nombre ?? 'Desconocido'}`}
                      </Text>
                    </PerjudicadoTableCell>
                  </StyledTableRow>
                  {rows.map(renderItem)}
                  {renderTotalRow(rows)}
                </React.Fragment>
              )
            })}
          </>
        )}
        <TableRow>
          {!isClosed && (
            <TableCell colSpan="9">
              <AddButton type="button" variant="transparent" onClick={onAdd}>
                <AiOutlineFolderAdd size={20} color={theme?.colors?.secondary?.main} />
                Añadir valoración
              </AddButton>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  )
}
