import React from 'react'
import styled from 'styled-components'

const ChipWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.ntrl_lightest.main};
  border: solid 1px ${({ theme }) => theme.colors.ntrl_light.main};
  color: ${({ theme, variant }) => theme.colors[variant]?.main};
  height: 24px;
  box-sizing: border-box;
`

const Chip = ({ className, children }) => {
  return <ChipWrapper className={className}>{children}</ChipWrapper>
}

export default Chip
