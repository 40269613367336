import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Checkbox } from '../../../../../../components/atoms/checkbox/Checkbox'
import { Input } from '../../../../../../components/atoms/input/Input'
import Text from '../../../../../../components/atoms/text/Text'
import { useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useUpdatePartialReportMutation } from '../../../../../../modules/reports/mutations'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Content = styled.article`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  overflow: visible;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const AddressField = styled(Input)`
  width: 550px;
`

export const AddResponsableScreen = () => {
  const location = useLocation()
  const responsable = location.state?.initialData ?? {}
  const isEditionMode = location.state?.isEditionMode ?? false
  const editionIdx = location.state?.idx ?? -1
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      responsables_nombre: responsable?.responsables_nombre ?? '',
      responsables_telefono: responsable?.responsables_telefono ?? '',
      responsables_nif: responsable?.responsables_nif ?? '',
      responsables_domicilioAfectado: responsable?.responsables_domicilioAfectado ?? '',
      responsables_aseguradora: responsable?.responsables_aseguradora ?? '',
      responsables_poliza: responsable?.responsables_poliza ?? '',
      responsables_expediente: responsable?.responsables_expediente ?? '',
      responsables_cuantiaAsegurada: responsable?.responsables_cuantiaAsegurada ?? '',
      responsables_asumeDanios: responsable?.responsables_asumeDanios ?? false,
    },
    mode: 'onBlur',
  })
  const updatePartialReportMutation = useUpdatePartialReportMutation()

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      const responsables = [...(report.responsables ?? [])]
      if (isEditionMode) {
        responsables[editionIdx] = values
      } else {
        responsables.push(values)
      }
      updatePartialReportMutation.mutate(
        {
          id: report.id,
          responsables,
        },
        {
          onSuccess: () => {
            resolve()
            navigate(-1)
          },
          onError: (error) => {
            reject(error)
            console.info(error)
          },
        },
      )
    })
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          {isEditionMode ? 'Edita al responsable' : 'Añadir responsable'}
        </Headline>

        <Text variant="black">
          {isEditionMode
            ? 'Actualiza los datos del responsable'
            : 'Completa los datos del responsable para añadirlo al informe'}
        </Text>
      </Header>

      <Content>
        <StyledInput {...register('responsables_nombre')} label="Nombre" placeholder="Escribe aquí" type="text" />
        <StyledInput
          {...register('responsables_telefono')}
          label="Teléfono"
          placeholder="Escribe aquí"
          type="tel"
          pattern="[0-9]{9}"
        />
        <StyledInput {...register('responsables_nif')} label="NIF" placeholder="Escribe aquí" type="text" />
        <AddressField
          {...register('responsables_domicilioAfectado')}
          label="Domicilio"
          placeholder="Escribe aquí"
          type="text"
        />
        <StyledInput
          {...register('responsables_aseguradora')}
          label="Aseguradora"
          placeholder="Escribe aquí"
          type="text"
        />
        <StyledInput {...register('responsables_poliza')} label="Poliza" placeholder="Escribe aquí" type="text" />
        <StyledInput {...register('responsables_expediente')} label="Exp." placeholder="Escribe aquí" type="text" />
        <StyledInput
          {...register('responsables_cuantiaAsegurada')}
          label="Cuantía asegurada"
          placeholder="Escribe aquí"
          type="number"
        />
        <Checkbox {...register('responsables_asumeDanios')}>Asume los daños</Checkbox>
      </Content>

      <Actions
        isSubmitting={isSubmitting}
        nextLabel={isEditionMode ? 'Guardar' : 'Añadir'}
        onBackClick={handleGoBack}
      />
    </Container>
  )
}
