import React, { useState } from 'react'
import { HiArrowLeft, HiDownload, HiOutlineLockClosed, HiOutlineLockOpen, HiOutlineLogout } from 'react-icons/hi'

import { useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { useDownload, withDownloadModal } from '../../../hooks/useDownload'
import { useModal } from '../../../hooks/useModal'
import { useAuth, useIsAdmin } from '../../../modules/auth'
import { useReportById, useReportStatus } from '../../../modules/reports/hooks/useReports'
import { useCloseReportMutation, useOpenReportMutation } from '../../../modules/reports/mutations'
import Button from '../../atoms/button/Button'
import { ButtonIcon } from '../../atoms/buttonIcon/ButtonIcon'
import { Nav } from '../../atoms/nav/Nav'
import { NavItem } from '../../atoms/navItem/NavItem'
import Tag from '../../atoms/tag/Tag'
import { CloseReportModal } from '../closeReportModal/CloseReportModal'
import { Modal } from '../modal/Modal'

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  background: #fff;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  flex-shrink: 0;
  z-index: 10;
  display: flex;
  gap: 24px;
  align-items: center;
  box-sizing: border-box;
  padding-right: 24px;
`

const ProfileOverlay = styled.div`
  position: absolute;
  top: 100%;
  right: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 240px;
  padding: 16px 0;
  margin-top: 8px;
`

const UserSection = styled.div`
  padding: 0 16px 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.ntrl_light.main};
`

const UserName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.ntrl_darkest.main};
`

const UserRole = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary.main};
  margin-top: 4px;
`

const MenuItem = styled.button`
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme, variant }) =>
    variant === 'negative' ? theme.colors.negative.main : theme.colors.ntrl_darkest.main};
  font-size: 14px;
  text-align: left;

  &:hover {
    background: ${({ theme }) => theme.colors.ntrl_lighter.main};
  }

  svg {
    color: ${({ theme, variant }) =>
      variant === 'negative' ? theme.colors.negative.main : theme.colors.ntrl_darker.main};
  }
`

const StyledButton = styled(Button)`
  padding: 3px 12px;
  height: auto;
`

const Avatar = styled.button`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: ${({ theme, isOpen }) => `solid 1px ${isOpen ? theme.colors.primary.main : theme.colors.ntrl_dark.main}`};
  background: ${({ theme, isOpen }) => (!isOpen ? 'transparent' : theme.colors.primary.main)};
  color: ${({ theme, isOpen }) => (isOpen ? theme.colors.white.main : theme.colors.ntrl_dark.main)};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
`

const StyledNavItem = styled(NavItem)`
  display: flex;
  align-items: center;

  & button {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
  }
`

const StyledNav = styled(Nav)`
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
`

const NavActions = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  padding: 8px 16px;
  height: 100%;
  box-sizing: border-box;
  border-left: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  border-right: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
`

const BackButton = styled(ButtonIcon)`
  padding: 0;
`

export const Header = withDownloadModal(({ showBack, showDownload = true, handleDownload, ...rest }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report, isLoading: isLoadingReport } = useReportById(reportId, { enabled: !!reportId })
  const { data: status, isLoading: isLoadingStatus } = useReportStatus(reportId)
  const [isDownloading] = useDownload({ reportId })
  const [{ isOpen, params }, { showModal, closeModal }] = useModal()
  const isAdmin = useIsAdmin()
  const [showProfile, setShowProfile] = useState(false)
  const { user, logout } = useAuth()

  const closeReportMutation = useCloseReportMutation()
  const openReportMutation = useOpenReportMutation()

  const handleCloseReport = () => {
    showModal()
  }

  const onCloseReport = () => {
    closeModal()
    closeReportMutation.mutate({ reportId })
  }

  const handleOpenReport = () => {
    openReportMutation.mutate({ reportId })
  }

  const toggleProfile = () => {
    setShowProfile(!showProfile)
  }

  const handleLogout = () => {
    setShowProfile(false)
    logout()
  }

  const { isClosed } = status ?? {}
  return (
    <StyledHeader {...rest}>
      <StyledNav>
        {showBack ? (
          <BackButton icon={<HiArrowLeft size={24} color={theme.colors.black.main} />} onClick={() => navigate(-1)} />
        ) : null}

        {!!reportId ? (
          <>
            {!isLoadingStatus ? (
              <Tag variant={isClosed ? 'negative' : 'positive'}>
                {isClosed ? <HiOutlineLockClosed size={18} /> : <HiOutlineLockOpen size={18} />}
                {isClosed ? 'Informe bloqueado' : 'Informe editable'}
              </Tag>
            ) : null}
            <StyledNavItem size="m" variant="ntrl_darkest">
              Expediente: {isLoadingReport ? 'Cargando...' : report?.expediente}
            </StyledNavItem>

            {showDownload ? (
              <StyledNavItem variant="primary" onClick={handleDownload} disabled={isDownloading}>
                <HiDownload size={18} />
                {isDownloading ? 'Descargando...' : 'Descargar'}
              </StyledNavItem>
            ) : null}
          </>
        ) : null}
      </StyledNav>

      <NavActions>
        {!isLoadingStatus ? (
          <>
            {isAdmin ? (
              <StyledButton type="button" bordered onClick={isClosed ? handleOpenReport : handleCloseReport}>
                {isClosed ? 'Editar informe' : 'Bloquear informe'}
              </StyledButton>
            ) : null}
          </>
        ) : null}
      </NavActions>

      <Avatar isOpen={showProfile} onClick={toggleProfile}>
        {(user.nombre ? user.nombre.charAt(0) : 'U').toUpperCase()}
      </Avatar>

      {showProfile && (
        <ProfileOverlay>
          <UserSection>
            <UserName>{user?.nombre}</UserName>
            <UserRole>{user?.numero}</UserRole>
          </UserSection>

          <MenuItem variant="negative" onClick={handleLogout}>
            <HiOutlineLogout size={20} />
            Log Out
          </MenuItem>
        </ProfileOverlay>
      )}

      {isAdmin ? (
        <Modal isOpen={isOpen} onRequestClose={closeModal}>
          <CloseReportModal {...params} onAccept={onCloseReport} onCancel={closeModal} />
        </Modal>
      ) : null}
    </StyledHeader>
  )
})
