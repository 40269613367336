import React from 'react'
import { generatePath, Outlet, useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Header } from '../../components/molecules/header/Header'
import { routes } from '../../services/Routing'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Content = styled.section`
  height: calc(100% - 60px);
  overflow: auto;
  padding: 24px;
  box-sizing: border-box;
`

export const SinglePageLayout = () => {
  const { reportId = '' } = useParams()
  const location = useLocation()
  const isDownloadAvailable =
    !location.pathname.includes(generatePath(routes.reportCreation, { reportId })) &&
    !location.pathname.includes(generatePath(routes.reportClonation, { reportId }))
  return (
    <Container>
      <Header showBack showDownload={isDownloadAvailable} />
      <Content>
        <Outlet />
      </Content>
    </Container>
  )
}
