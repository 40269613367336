import React, { memo } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text } from '../../../../components'
import Logo from '../../../../components/atoms/logo/Logo'
import { ErrorPage } from '../../../../components/molecules/errorPage/ErrorPage'
import {
  useRelatedReports,
  useReportById,
  useReportCapitales,
  useStoredComments,
  useStoredReport,
} from '../../../../modules/reports/hooks/useReports'
import { getUsableRelatedReports, hasNewerReports } from '../../../../modules/reports/utils'
import { routes } from '../../../../services/Routing'

const Container = styled.main`
  height: 100vh;
  overflow: hidden;
`

const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
`

export const DashboardLayout = memo(({ className, children }) => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: expediente, isLoading: isLoadingReport, isError, error } = useReportById(reportId)
  const { data: report, isLoading: isReportLoading } = useStoredReport(reportId)
  const { data: relatedReports, isLoading: isLoadingRelatedReports } = useRelatedReports(reportId)
  const { isLoading: isLoadingComments } = useStoredComments(reportId)
  const { isLoading: isLoadingCapitales } = useReportCapitales(reportId, { enabled: !!report })
  const isLoading =
    isLoadingComments || isReportLoading || isLoadingRelatedReports || isLoadingReport || isLoadingCapitales

  React.useEffect(() => {
    if (isLoading) {
      return
    }

    if (report && expediente) {
      const isExpedienteClosed = expediente?.cerrado === 1
      const isEmpty = !expediente.hasReportData

      // If the report is closed and it's empty, we redirect to the closed report screen
      // If the report is closed and it's not empty, we redirect to the general information screen
      if (isExpedienteClosed) {
        if (isEmpty) {
          return navigate(generatePath(routes.closed, { reportId }))
        } else {
          return navigate(generatePath(routes.informacionGeneral, { reportId }))
        }
      }

      // If the report is empty and there are related reports, we redirect to the clone screen
      const usableRelatedReports = getUsableRelatedReports({ relatedReports })
      if (isEmpty && usableRelatedReports.length !== 0) {
        return navigate(generatePath(routes.clone, { reportId }))
      }

      // If the report is not empty and there are newer reports, we redirect to the warning screen
      if (!isEmpty && hasNewerReports({ report: expediente, relatedReports })) {
        return navigate(generatePath(routes.warning, { reportId }))
      }

      if (!report.tipoExpediente) {
        return navigate(generatePath(routes.tipoExpediente, { reportId }))
      }

      return navigate(generatePath(routes.informacionGeneral, { reportId }))
    }
  }, [isLoading, report, expediente, reportId, relatedReports, navigate])

  if (isError) {
    let message = 'No hemos podido recuperar el expediente en este momento'
    switch (error.cause) {
      case 403:
      case 401:
        message = 'No tienes permisos para acceder a este expediente'
        break

      case 404:
        message = 'Parece que el expediente que buscas no existe'
        break

      default:
        break
    }
    return <ErrorPage message={message} />
  }

  if (isLoading) {
    return (
      <Loader>
        <Logo />
        <Text variant="black">Cargando...</Text>
      </Loader>
    )
  }

  if (!report) {
    return <ErrorPage message="No hemos podido recuperar el expediente en este momento" />
  }

  return (
    <Container className={className}>
      <Outlet />
    </Container>
  )
})
