import React, { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import {
  useIsClosed,
  useIsDefensaJuridica,
  useSaveReport,
  useStoredReport,
} from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute, routes } from '../../../../../../services/Routing'
import Utils from '../../../../../../services/Utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'
import { ValoracionDanosTable } from './components/ValoracionDanosTable'
import { ValoracionDefensaJuridicaTable } from './components/ValoracionDefensaJuridicaTable'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const TableWrapper = styled.div`
  /* overflow: auto; */
`

const Summary = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 12px;
`

const StyledInput = styled(Input)`
  width: 200px;
`

const STEP = 'valoracionDanios'
export const ValoracionScreen = () => {
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isDefensaJuridica = useIsDefensaJuridica()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    getValues,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      valoracionDanios_Table: report?.valoracionDanios_Table ?? [],
    },
    mode: 'onBlur',
  })
  const { fields, remove, move } = useFieldArray({
    control,
    name: 'valoracionDanios_Table',
  })
  const { saveData } = useAutoSave()

  const handleRequestAddValoracion = useCallback(
    ({ initialData = {}, isEditionMode = false, idx }) => {
      navigate(generatePath(routes.addValoracion, { reportId }), {
        state: { initialData, isEditionMode, idx },
      })
    },
    [navigate, reportId],
  )

  const handleSaveReport = useSaveReport()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  const handleMoveUp = useCallback(
    (idx) => {
      const nextIdx = idx - 1
      if (nextIdx < 0) return
      move(idx, nextIdx)
      handleFormChange()
    },
    [handleFormChange, move],
  )

  const handleMoveDown = useCallback(
    (idx) => {
      const nextIdx = idx + 1
      if (nextIdx >= fields.length) return
      move(idx, nextIdx)
      handleFormChange()
    },
    [fields?.length, handleFormChange, move],
  )

  const totalMinutar = React.useMemo(() => {
    return fields?.reduce((acc, item) => {
      if (!item || item.isHeader || item?.ValorNuevo < 0) {
        return acc
      }
      return acc.add(Utils.toDinero(parseFloat(isDefensaJuridica ? item?.TotalPropuesta ?? 0 : item?.ValorNuevo ?? 0)))
    }, Utils.toDinero(0))
  }, [fields, isDefensaJuridica])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Valoración de daños
        </Headline>

        <Text variant="black">Añade las valoraciones de los daños producidos por el siniestro.</Text>
      </Header>

      <TableWrapper>
        {report.tipoExpediente === Utils.TIPO_EXPEDIENTE.DANOS ? (
          <ValoracionDanosTable valoraciones={fields} onAdd={handleRequestAddValoracion} onRemove={remove} />
        ) : (
          <ValoracionDefensaJuridicaTable
            valoraciones={fields}
            onAdd={handleRequestAddValoracion}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
            onRemove={remove}
          />
        )}
      </TableWrapper>

      <Summary>
        <Text variant="black" weight="600" size="sm">
          Total importe valorado para Minutar
        </Text>
        <StyledInput disabled value={totalMinutar.convertPrecision(2).toUnit()} />
      </Summary>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
