import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components/macro'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Content = styled.section`
  overflow: auto;
  padding: 24px;
  box-sizing: border-box;
`

export const QuetionPageLayout = () => {
  return (
    <Container>
      <Content>
        <Outlet />
      </Content>
    </Container>
  )
}
