import React, { useCallback, useMemo } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { AiOutlineDelete } from 'react-icons/ai'
import { FiPlus } from 'react-icons/fi'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import {
  Button,
  Editor,
  Input,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { HighlightedBlock } from '../../../../../../components/molecules/highlightedBlock/HighlightedBlock'
import { RadioGroup } from '../../../../../../components/molecules/radioGroup/RadioGroup'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import {
  useIsClosed,
  useReportCapitales,
  useSaveReport,
  useStoredReport,
} from '../../../../../../modules/reports/hooks/useReports'
import { sanitizeFloatValue, showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute } from '../../../../../../services/Routing'
import Utils from '../../../../../../services/Utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const articulos = [
  {
    label: 'Aguas',
    value: 'Aguas',
  },
  {
    label: 'Robo',
    value: 'Robo',
  },
  {
    label: 'D. Eléctrico',
    value: 'D. Eléctrico',
  },
  {
    label: 'Explosión',
    value: 'Explosión',
  },
  {
    label: 'Viento',
    value: 'Viento',
  },
  {
    label: 'Lluvías',
    value: 'Lluvías',
  },
  {
    label: 'Granizo',
    value: 'Granizo',
  },
  {
    label: 'Nieve',
    value: 'Nieve',
  },
  {
    label: 'Incendio',
    value: 'Incendio',
  },
  {
    label: 'Atraco',
    value: 'Atraco',
  },
  {
    label: 'Act. Vand.',
    value: 'Act. Vand.',
  },
  {
    label: 'Respon. Civil',
    value: 'Respon. Civil',
  },
  {
    label: 'Otro',
    value: 'Otro',
  },
]

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Inputs = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
`

const ShortInput = styled(Input)`
  width: 124px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const StyledSelect = styled(Select)`
  width: 260px;
`

const InvisibleInput = styled(Input)`
  width: 260px;

  ${({ hidden }) => {
    hidden &&
      css`
        display: none;
      `
  }}
`

const Block = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Columns = styled(Block)`
  flex-direction: row;
  gap: 60px;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`

const GridHeader = styled.div`
  grid-column: span 2;
  padding-bottom: 6px;
  margin-top: 12px;
  border-bottom: solid 1px ${({ theme }) => theme.colors.ntrl_darker.main};
`

const GridFullRow = styled.div`
  grid-column: span 2;
`

const FranquiciaBlock = styled(HighlightedBlock)`
  grid-column: span 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`

const TableWrapper = styled(Block)`
  /* overflow: auto; */
`

const StyledTableRow = styled(TableRow)`
  background-color: ${({ theme }) => `${theme?.colors?.ntrl_lighter?.main}`};
`

const StyledTableCell = styled(TableCell)`
  padding: 10px 20px;
  background: none;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.negative.main};
  gap: 6px;
  font-weight: 400;
  width: 48px;
`

const sanitizeValues = (values) => {
  return {
    propuestaFinal_RegProporcional_Rep_Propios: sanitizeFloatValue(values?.propuestaFinal_RegProporcional_Rep_Propios),
    propuestaFinal_RegProporcional_Ind_Propios: sanitizeFloatValue(values?.propuestaFinal_RegProporcional_Ind_Propios),
    propuestaFinal_RegProporcional_Total_Propios: sanitizeFloatValue(
      values?.propuestaFinal_RegProporcional_Total_Propios,
    ),
    propuestaFinal_RegProporcional_Rep_Terceros: sanitizeFloatValue(
      values?.propuestaFinal_RegProporcional_Rep_Terceros,
    ),
    propuestaFinal_RegProporcional_Ind_Terceros: sanitizeFloatValue(
      values?.propuestaFinal_RegProporcional_Ind_Terceros,
    ),
    propuestaFinal_RegProporcional_Total_Terceros: sanitizeFloatValue(
      values?.propuestaFinal_RegProporcional_Total_Terceros,
    ),
    propuestaFinal_Franquicia_Rep_Propios: sanitizeFloatValue(values?.propuestaFinal_Franquicia_Rep_Propios),
    propuestaFinal_Franquicia_Ind_Propios: sanitizeFloatValue(values?.propuestaFinal_Franquicia_Ind_Propios),
    propuestaFinal_Franquicia_Rep_Terceros: sanitizeFloatValue(values?.propuestaFinal_Franquicia_Rep_Terceros),
    propuestaFinal_Franquicia_Ind_Terceros: sanitizeFloatValue(values?.propuestaFinal_Franquicia_Ind_Terceros),
    propuestaFinal_Franquicias: (values?.propuestaFinal_Franquicias ?? []).map((franquicia) => ({
      ...franquicia,
      Propios: {
        Reparar: sanitizeFloatValue(franquicia?.Propios?.Reparar),
        Indemnizar: sanitizeFloatValue(franquicia?.Propios?.Indemnizar),
      },
      Terceros: {
        Reparar: sanitizeFloatValue(franquicia?.Terceros?.Reparar),
        Indemnizar: sanitizeFloatValue(franquicia?.Terceros?.Indemnizar),
      },
    })),
  }
}

const STEP = 'propuestaFinal'
export const PropuestaFinalScreen = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const { data: capitales } = useReportCapitales(reportId)
  const {
    getValues,
    setValue,
    control,
    register,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      propuestaFinal_Fraude: report?.propuestaFinal_Fraude ?? '',
      propuestaFinal_AceptadoPorCliente: report?.propuestaFinal_AceptadoPorCliente ?? '',
      propuestaFinal_SiniestroCubierto: report?.propuestaFinal_SiniestroCubierto ?? '',
      propuestaFinal_ArticuloAplicado: report?.propuestaFinal_ArticuloAplicado ?? '',
      propuestaFinal_ArticuloAplicado_Custom: report?.propuestaFinal_ArticuloAplicado_Custom ?? '',
      propuestaFinal_Recomendacion: report?.propuestaFinal_Recomendacion ?? '',
      propuestaFinal_Recomendacion_Motivo: report?.propuestaFinal_Recomendacion_Motivo ?? '',
      propuestaFinal_SiniestroAsumidoConsorcio: report?.propuestaFinal_SiniestroAsumidoConsorcio ?? '',
      propuestaFinal_SiniestroAsumidoConsorcio_Motivo: report?.propuestaFinal_SiniestroAsumidoConsorcio_Motivo ?? '',
      propuestaFinal_RegProporcional_Rep_Propios: report?.propuestaFinal_RegProporcional_Rep_Propios ?? 0,
      propuestaFinal_RegProporcional_Ind_Propios: report?.propuestaFinal_RegProporcional_Ind_Propios ?? 0,
      propuestaFinal_RegProporcional_Total_Propios: report?.propuestaFinal_RegProporcional_Total_Propios ?? 0,
      propuestaFinal_Franquicia_Rep_Propios: report?.propuestaFinal_Franquicia_Rep_Propios ?? 0,
      propuestaFinal_Franquicia_Ind_Propios: report?.propuestaFinal_Franquicia_Ind_Propios ?? 0,
      propuestaFinal_RegProporcional_Rep_Terceros: report?.propuestaFinal_RegProporcional_Rep_Terceros ?? 0,
      propuestaFinal_RegProporcional_Ind_Terceros: report?.propuestaFinal_RegProporcional_Ind_Terceros ?? 0,
      propuestaFinal_RegProporcional_Total_Terceros: report?.propuestaFinal_RegProporcional_Total_Terceros ?? 0,
      propuestaFinal_Franquicia_Rep_Terceros: report?.propuestaFinal_Franquicia_Rep_Terceros ?? 0,
      propuestaFinal_Franquicia_Ind_Terceros: report?.propuestaFinal_Franquicia_Ind_Terceros ?? 0,
      propuestaFinal_Franquicias: report?.propuestaFinal_Franquicias ?? [
        {
          Nombre: '',
          Propios: {
            Reparar: 0,
            Indemnizar: 0,
          },
          Terceros: {
            Reparar: 0,
            Indemnizar: 0,
          },
        },
      ],
    },
    mode: 'onBlur',
  })
  const {
    fields: franquicias,
    append: appendFranquicia,
    remove: removeFranquicia,
  } = useFieldArray({
    control,
    name: 'propuestaFinal_Franquicias',
  })
  const watchFranquicias = useWatch({ control, name: 'propuestaFinal_Franquicias' })

  const watchArticuloAplicado = watch('propuestaFinal_ArticuloAplicado')
  const watchRepPropios = watch('propuestaFinal_RegProporcional_Rep_Propios')
  const watchIndPropios = watch('propuestaFinal_RegProporcional_Ind_Propios')
  const watchRepTerceros = watch('propuestaFinal_RegProporcional_Rep_Terceros')
  const watchIndTerceros = watch('propuestaFinal_RegProporcional_Ind_Terceros')

  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleAddFranquicia = () => {
    appendFranquicia({
      Nombre: '',
      Propios: {
        Reparar: 0,
        Indemnizar: 0,
      },
      Terceros: {
        Reparar: 0,
        Indemnizar: 0,
      },
    })
  }

  const propios = useMemo(() => {
    if (!capitales || !Object.keys(capitales).length === 0) {
      return {}
    }

    return Object.keys(capitales).reduce((result, capital) => {
      const capitalPropios =
        report?.valoracionDanios_Table?.filter(
          ({ Capital, TipoDanio }) => Capital === capital && (TipoDanio === 'propio' || TipoDanio === 'estetico'),
        ) ?? []

      result[capital] = capitalPropios.reduce(
        (total, { RepInd, TotalPropuesta }) => {
          if (!total[RepInd]) {
            console.error(new Error('RepInd not found'))
            return total
          }
          total[RepInd] = total[RepInd].add(Utils.toDinero(parseFloat(TotalPropuesta)))
          return total
        },
        {
          reparable: Utils.toDinero(0),
          indemnizable: Utils.toDinero(0),
        },
      )
      return result
    }, {})
  }, [capitales, report])

  const terceros = useMemo(() => {
    if (!capitales || !Object.keys(capitales).length === 0) {
      return {}
    }

    return Object.keys(capitales).reduce((result, capital) => {
      const capitalTerceros =
        report?.valoracionDanios_Table?.filter(
          ({ Capital, TipoDanio }) => Capital === capital && TipoDanio === 'perjudicados',
        ) ?? []

      result[capital] = capitalTerceros.reduce(
        (total, { RepInd, TotalPropuesta }) => {
          if (!total[RepInd]) {
            console.error(new Error('RepInd not found'))
            return total
          }
          total[RepInd] = total[RepInd].add(Utils.toDinero(parseFloat(TotalPropuesta)))
          return total
        },
        {
          reparable: Utils.toDinero(0),
          indemnizable: Utils.toDinero(0),
        },
      )
      return result
    }, {})
  }, [capitales, report])

  const totalPropiosRep = useMemo(() => {
    const sanitizedRepPropios = isNaN(Number(watchRepPropios)) ? 0 : Number(watchRepPropios)
    // Handle old reports
    const oldFranquiciaRepPropios = Number(getValues().propuestaFinal_Franquicia_Rep_Propios)

    const sanitizedFranquiciaRepPropios = watchFranquicias?.reduce((total, franquicia) => {
      const { Propios } = franquicia
      if (isNaN(Number(Propios.Reparar))) {
        return total
      }
      return total + Number(Propios.Reparar)
    }, 0)
    const total = Object.values(propios)
      .reduce((total, { reparable }) => total.add(reparable), Utils.toDinero(0))
      .subtract(Utils.toDinero(sanitizedRepPropios))
      .subtract(Utils.toDinero(sanitizedFranquiciaRepPropios))
      .subtract(Utils.toDinero(isNaN(oldFranquiciaRepPropios) ? 0 : oldFranquiciaRepPropios))

    if (total.convertPrecision(2).toUnit() < 0) {
      return Utils.toDinero(0)
    }
    return total
  }, [watchRepPropios, getValues, watchFranquicias, propios])

  const totalPropiosInd = useMemo(() => {
    const sanitizedIndPropios = isNaN(Number(watchIndPropios)) ? 0 : Number(watchIndPropios)
    // Handle old reports
    const oldFranquiciaIndPropios = Number(getValues().propuestaFinal_Franquicia_Ind_Propios)

    const sanitizedFranquiciaIndPropios = watchFranquicias?.reduce((total, franquicia) => {
      const { Propios } = franquicia
      if (isNaN(Number(Propios.Indemnizar))) {
        return total
      }
      return total + Number(Propios.Indemnizar)
    }, 0)
    const total = Object.values(propios)
      .reduce((total, { indemnizable }) => total.add(indemnizable), Utils.toDinero(0))
      .subtract(Utils.toDinero(sanitizedIndPropios))
      .subtract(Utils.toDinero(sanitizedFranquiciaIndPropios))
      .subtract(Utils.toDinero(isNaN(oldFranquiciaIndPropios) ? 0 : oldFranquiciaIndPropios))

    if (total.convertPrecision(2).toUnit() < 0) {
      return Utils.toDinero(0)
    }
    return total
  }, [getValues, propios, watchFranquicias, watchIndPropios])

  const totalTercerosRep = useMemo(() => {
    const sanitizedRepTerceros = isNaN(Number(watchRepTerceros)) ? 0 : Number(watchRepTerceros)
    // Handle old reports
    const oldFranquiciaRepTerceros = Number(getValues().propuestaFinal_Franquicia_Rep_Terceros)

    const sanitizedFranquiciaRepTerceros = watchFranquicias?.reduce((total, franquicia) => {
      const { Terceros } = franquicia
      if (isNaN(Number(Terceros.Reparar))) {
        return total
      }
      return total + Number(Terceros.Reparar)
    }, 0)
    const total = Object.values(terceros)
      .reduce((total, { reparable }) => total.add(reparable), Utils.toDinero(0))
      .subtract(Utils.toDinero(sanitizedRepTerceros))
      .subtract(Utils.toDinero(sanitizedFranquiciaRepTerceros))
      .subtract(Utils.toDinero(isNaN(oldFranquiciaRepTerceros) ? 0 : oldFranquiciaRepTerceros))

    if (total.convertPrecision(2).toUnit() < 0) {
      return Utils.toDinero(0)
    }
    return total
  }, [getValues, terceros, watchFranquicias, watchRepTerceros])

  const totalTercerosInd = useMemo(() => {
    const sanitizedIndTerceros = isNaN(Number(watchIndTerceros)) ? 0 : Number(watchIndTerceros)
    // Handle old reports
    const oldFranquiciaIndTerceros = Number(getValues().propuestaFinal_Franquicia_Ind_Terceros)

    const sanitizedFranquiciaIndTerceros = watchFranquicias?.reduce((total, franquicia) => {
      const { Terceros } = franquicia
      if (isNaN(Number(Terceros.Indemnizar))) {
        return total
      }
      return total + Number(Terceros.Indemnizar)
    }, 0)
    const total = Object.values(terceros)
      .reduce((total, { indemnizable }) => total.add(indemnizable), Utils.toDinero(0))
      .subtract(Utils.toDinero(sanitizedIndTerceros))
      .subtract(Utils.toDinero(sanitizedFranquiciaIndTerceros))
      .subtract(Utils.toDinero(isNaN(oldFranquiciaIndTerceros) ? 0 : oldFranquiciaIndTerceros))

    if (total.convertPrecision(2).toUnit() < 0) {
      return Utils.toDinero(0)
    }
    return total
  }, [getValues, terceros, watchFranquicias, watchIndTerceros])

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({
      id: STEP,
      reportId,
      data: {
        ...getValues(),
        ...sanitizeValues(getValues()),
      },
    })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    const values = getValues()
    handleSaveReport({
      data: {
        ...values,
        ...sanitizeValues(values),
      },
    })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])
  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: {
          ...values,
          propuestaFinal_RegProporcional_Rep_Propios: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Rep_Propios,
          ),
          propuestaFinal_RegProporcional_Ind_Propios: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Ind_Propios,
          ),
          propuestaFinal_RegProporcional_Total_Propios: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Total_Propios,
          ),
          propuestaFinal_RegProporcional_Rep_Terceros: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Rep_Terceros,
          ),
          propuestaFinal_RegProporcional_Ind_Terceros: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Ind_Terceros,
          ),
          propuestaFinal_RegProporcional_Total_Terceros: sanitizeFloatValue(
            values?.propuestaFinal_RegProporcional_Total_Terceros,
          ),
        },
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  React.useEffect(() => {
    if (watchArticuloAplicado === 'Otros') {
      return
    }

    setValue('propuestaFinal_ArticuloAplicado_Custom', '', { shouldDirty: true, shouldTouch: true })
  }, [setValue, watchArticuloAplicado])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Propuesta final
        </Headline>

        <Text variant="black">
          Aquí tienes el resumen del peritaje. Recuerda que las cantidades llevan los impuestos incluidos.
        </Text>
      </Header>

      <RadioGroup>
        <Text variant="black">Siniestro cubierto</Text>

        <Radio value="SI" {...register('propuestaFinal_SiniestroCubierto')}>
          Sí
        </Radio>
        <Radio value="NO" {...register('propuestaFinal_SiniestroCubierto')}>
          No
        </Radio>
      </RadioGroup>

      <Inputs>
        <Controller
          render={({ field: { onChange, ...rest } }) => (
            <StyledSelect
              label="Artículo aplicado"
              options={articulos}
              onChange={(value) => {
                onChange(value)
                handleFormChange()
              }}
              {...rest}
            />
          )}
          name="propuestaFinal_ArticuloAplicado"
          control={control}
          defaultValue=""
        />

        <InvisibleInput
          {...register('propuestaFinal_ArticuloAplicado_Custom')}
          label=""
          placeholder="Escribe aquí"
          type="text"
          hidden={watchArticuloAplicado !== 'Otro'}
        />
      </Inputs>

      <Grid>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Text variant="black" weight="600" size="sm">
                    Daños propios
                  </Text>
                </TableCell>
              </TableRow>

              <StyledTableRow>
                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Concepto
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Reparar
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Indemnizar
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Total
                  </Text>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {capitales
                ? Object.keys(capitales).map((capital) => {
                    const reparar = propios[capital].reparable.convertPrecision(2).toUnit()
                    const indemnizar = propios[capital].indemnizable.convertPrecision(2).toUnit()

                    if (reparar === 0 && indemnizar === 0) {
                      return null
                    }

                    const total = propios[capital].reparable
                      .add(propios[capital].indemnizable)
                      .convertPrecision(2)
                      .toUnit()
                    return (
                      <TableRow key={`danios-propios-capital-${capital}`}>
                        <TableCell>
                          <Text variant="black" weight="600" size="sm">
                            {capital}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {reparar}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {indemnizar}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {total}
                          </Text>
                        </TableCell>
                      </TableRow>
                    )
                  })
                : null}
            </TableBody>
          </Table>
        </TableWrapper>

        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>
                  <Text variant="black" weight="600" size="sm">
                    Daños a terceros
                  </Text>
                </TableCell>
              </TableRow>

              <StyledTableRow>
                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Concepto
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Reparar
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Indemnizar
                  </Text>
                </StyledTableCell>

                <StyledTableCell>
                  <Text variant="black" weight="600" size="sm">
                    Total
                  </Text>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {capitales
                ? Object.keys(capitales).map((capital) => {
                    const reparar = terceros[capital].reparable.convertPrecision(2).toUnit()
                    const indemnizar = terceros[capital].indemnizable.convertPrecision(2).toUnit()

                    if (reparar === 0 && indemnizar === 0) {
                      return null
                    }

                    const total = terceros[capital].reparable
                      .add(terceros[capital].indemnizable)
                      .convertPrecision(2)
                      .toUnit()
                    return (
                      <TableRow key={`danios-terceros-capital-${capital}`}>
                        <TableCell>
                          <Text variant="black" weight="600" size="sm">
                            {capital}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {reparar}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {indemnizar}
                          </Text>
                        </TableCell>

                        <TableCell>
                          <Text variant="black" size="sm">
                            {total}
                          </Text>
                        </TableCell>
                      </TableRow>
                    )
                  })
                : null}
            </TableBody>
          </Table>
        </TableWrapper>

        <GridHeader>
          <Text variant="black">Regla Proprocional (-)</Text>
        </GridHeader>

        <Block>
          <Inputs>
            <ShortInput
              {...register('propuestaFinal_RegProporcional_Rep_Propios')}
              label="Reparar Propios"
              placeholder="Escribe aquí"
              type="number"
            />

            <ShortInput
              {...register('propuestaFinal_RegProporcional_Ind_Propios')}
              label="Ind. Propios"
              placeholder="Escribe aquí"
              type="number"
            />
          </Inputs>
        </Block>

        <Block>
          <Inputs>
            <ShortInput
              {...register('propuestaFinal_RegProporcional_Rep_Terceros')}
              label="Reparar Terceros"
              placeholder="Escribe aquí"
              type="number"
            />

            <ShortInput
              {...register('propuestaFinal_RegProporcional_Ind_Terceros')}
              label="Ind. Terceros"
              placeholder="Escribe aquí"
              type="number"
            />
          </Inputs>
        </Block>

        <GridHeader>
          <Text variant="black">Franquicias</Text>
        </GridHeader>
        {franquicias.map((franquicia, index) => (
          <FranquiciaBlock key={franquicia.id}>
            <GridFullRow>
              <Inputs>
                <StyledInput
                  {...register(`propuestaFinal_Franquicias.${index}.Nombre`)}
                  label="Nombre"
                  placeholder="Escribe aquí"
                  type="text"
                />

                {index !== 0 ? (
                  <RemoveButton
                    type="button"
                    variant="negative"
                    onClick={() => {
                      removeFranquicia(index)
                    }}
                  >
                    <AiOutlineDelete size={20} color={theme?.colors?.white?.main} />
                  </RemoveButton>
                ) : null}
              </Inputs>
            </GridFullRow>

            <Block>
              <Inputs>
                <ShortInput
                  {...register(`propuestaFinal_Franquicias.${index}.Propios.Reparar`)}
                  label="Reparar"
                  placeholder="Escribe aquí"
                  type="number"
                />

                <ShortInput
                  {...register(`propuestaFinal_Franquicias.${index}.Propios.Indemnizar`)}
                  label="Ind. Propios"
                  placeholder="Escribe aquí"
                  type="number"
                />
              </Inputs>
            </Block>

            <Block>
              <Inputs>
                <ShortInput
                  {...register(`propuestaFinal_Franquicias.${index}.Terceros.Reparar`)}
                  label="Reparar Terceros"
                  placeholder="Escribe aquí"
                  type="number"
                />

                <ShortInput
                  {...register(`propuestaFinal_Franquicias.${index}.Terceros.Indemnizar`)}
                  label="Ind. Terceros"
                  placeholder="Escribe aquí"
                  type="number"
                />
              </Inputs>
            </Block>
          </FranquiciaBlock>
        ))}

        <GridFullRow>
          <AddButton type="button" variant="transparent" onClick={handleAddFranquicia}>
            <FiPlus size={20} color={theme?.colors?.secondary?.main} />
            Añadir franquicia
          </AddButton>
        </GridFullRow>

        <GridHeader>
          <Text variant="black">Propuesta final</Text>
        </GridHeader>

        <Block>
          <Inputs>
            <ShortInput
              label="Total Rep. Propios"
              disabled
              type="number"
              value={totalPropiosRep.convertPrecision(2).toUnit()}
            />

            <ShortInput
              label="Total Ind. Propios"
              disabled
              type="number"
              value={totalPropiosInd.convertPrecision(2).toUnit()}
            />

            <ShortInput
              label="Total Propios"
              disabled
              type="number"
              value={totalPropiosRep.add(totalPropiosInd).convertPrecision(2).toUnit()}
            />
          </Inputs>
        </Block>

        <Block>
          <Inputs>
            <ShortInput
              label="Total Rep. Terceros"
              disabled
              type="number"
              value={totalTercerosRep.convertPrecision(2).toUnit()}
            />

            <ShortInput
              label="Total Ind. Terceros"
              disabled
              type="number"
              value={totalTercerosInd.convertPrecision(2).toUnit()}
            />

            <ShortInput
              label="Total Terceros"
              disabled
              type="number"
              value={totalTercerosRep.add(totalTercerosInd).convertPrecision(2).toUnit()}
            />
          </Inputs>
        </Block>
      </Grid>

      <Block>
        <Text variant="black">Recomendación</Text>

        <RadioGroup>
          <Radio value="Mantener" {...register('propuestaFinal_Recomendacion')}>
            Mantener
          </Radio>
          <Radio value="Anular" {...register('propuestaFinal_Recomendacion')}>
            Anular
          </Radio>
          <Radio value="Revisar" {...register('propuestaFinal_Recomendacion')}>
            Revisar
          </Radio>
        </RadioGroup>

        <Editor
          disablePlugins
          value={getValues()?.propuestaFinal_Recomendacion_Motivo}
          onChange={(value) =>
            setValue('propuestaFinal_Recomendacion_Motivo', value, { shouldDirty: true, shouldTouch: true })
          }
        />
      </Block>

      <Block>
        <Text variant="black">Siniestro asumido por el consorcio</Text>

        <RadioGroup>
          <Radio value="SI" {...register('propuestaFinal_SiniestroAsumidoConsorcio')}>
            Sí
          </Radio>
          <Radio value="NO" {...register('propuestaFinal_SiniestroAsumidoConsorcio')}>
            No
          </Radio>
        </RadioGroup>

        <Editor
          disablePlugins
          value={getValues()?.propuestaFinal_SiniestroAsumidoConsorcio_Motivo}
          onChange={(value) =>
            setValue('propuestaFinal_SiniestroAsumidoConsorcio_Motivo', value, { shouldDirty: true, shouldTouch: true })
          }
        />
      </Block>

      <Columns>
        <Column>
          <Text variant="black">¿Fraude?</Text>

          <RadioGroup>
            <Radio value="SI" {...register('propuestaFinal_Fraude')}>
              Sí
            </Radio>
            <Radio value="NO" {...register('propuestaFinal_Fraude')}>
              No
            </Radio>
          </RadioGroup>
        </Column>

        <Column>
          <Text variant="black">¿Propuesta aceptada por el cliente?</Text>

          <RadioGroup>
            <Radio value="SI" {...register('propuestaFinal_AceptadoPorCliente')}>
              Sí
            </Radio>
            <Radio value="NO" {...register('propuestaFinal_AceptadoPorCliente')}>
              No
            </Radio>
          </RadioGroup>
        </Column>
      </Columns>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
