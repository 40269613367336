import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import Button from '../../components/atoms/button/Button'
import Text from '../../components/atoms/text/Text'
import { routes } from '../../services/Routing'
import { Headline } from '../dashboard/creation/components/headline'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 60px;
`

const Content = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

const StyledIcon = styled(MdErrorOutline)`
  margin-bottom: 36px;
`

export const ErrorScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const theme = useTheme()

  const handleExit = () => {
    navigate(generatePath(routes.home, { reportId }))
  }

  return (
    <Container>
      <Content>
        <StyledIcon size={60} color={theme.colors.negative.main} />
        <Headline size="l" weight="500" variant="black">
          Oops! Ha ocurrido un error
        </Headline>
        <Text variant="black" align="center">
          Parece que algo ha ido mal, si el problema persiste, por favor contacta con soporte.
        </Text>

        <Button type="button" onClick={handleExit}>
          ¡Sácame de aquí! 🚀
        </Button>
      </Content>
    </Container>
  )
}
