import React from 'react'
import { generatePath, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Text } from '../../../components'
import StepperList from '../../../components/molecules/stepperList/StepperList'
import { routes } from '../../../services/Routing'
import socketManager from '../../../services/SocketManager'
import { Headline } from '../../dashboard/creation/components/headline'
import { PeritosQueryClient } from '../../../modules'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 60px;
`

const Content = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

const STEPS = [
  {
    id: -1,
    title: 'Recopilando información del informe a copiar',
  },
  {
    id: 0,
    title: 'Copiando informe',
  },
  {
    id: 1,
    title: 'Copiando anexos y fotografías',
  },
  {
    id: 2,
    title: 'Guardando informe',
  },
  {
    id: 3,
    title: 'Finalizando informe copiado',
  },
]

export const ReportClonationScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const location = useLocation()
  const state = location.state
  const isWorking = React.useRef(false)
  const [currentStep, setCurrentStep] = React.useState(0)

  React.useEffect(() => {
    if (isWorking.current || !reportId || !state?.from) {
      return
    }

    isWorking.current = true
    socketManager
      .connect()
      .then(() => {
        socketManager.cloneReport({
          data: {
            idExpediente: Number(reportId),
            from: state?.from,
          },
          onReportReady: async (data) => {
            await socketManager.disconnect()
            PeritosQueryClient.removeQueries({ queryKey: ['reports', reportId.toString()] })
            setCurrentStep(Math.floor(Number(STEPS.length)))
            setTimeout(() => {
              navigate(generatePath(routes.home, { reportId }), { replace: true })
            }, 1000)
          },
          onProgress: (data) => {
            const { step } = data
            setCurrentStep(Math.floor(Number(step)))
          },
          onError: async () => {
            toast.error('Ha ocurrido un error y no hemos podido generar el informe')
            await socketManager.disconnect()
            navigate(generatePath(routes.error, { reportId }), { replace: true })
          },
        })
      })
      .catch(async () => {
        toast.error('Ha ocurrido un error y no hemos podido generar el informe')
        await socketManager.disconnect()
        navigate(generatePath(routes.error, { reportId }), { replace: true })
      })
  }, [navigate, reportId, state?.downloadOption, state.format, state?.from])

  if (!state?.from) {
    return <Navigate to={generatePath(routes.home, { reportId })} replace />
  }

  return (
    <Container>
      <Content>
        <Headline size="l" weight="500" variant="black">
          Preparando tu informe
        </Headline>
        <Text variant="black" align="center">
          Estamos copiando la información, por favor, no abandones esta página. El proceso puede tardar unos segundos.
        </Text>
      </Content>

      <StepperList steps={STEPS} activeIndex={currentStep} />
    </Container>
  )
}
