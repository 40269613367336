import { transparentize } from 'polished'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { BsFileEarmarkMedical, BsTrash3 } from 'react-icons/bs'
import { CgReorder } from 'react-icons/cg'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { Alert, Button, CircleLoader, ExternalLink, Input, Modal, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { ImageEditor } from '../../../../../../components/molecules/imageEditor/ImageEditor'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import { useModal } from '../../../../../../hooks/useModal'
import {
  useIsClosed,
  useIsDefensaJuridica,
  useStoredAnexos,
  useStoredReport,
} from '../../../../../../modules/reports/hooks/useReports'
import {
  useAddAnexosMutation,
  useRemoveStoredAttachment,
  useUpdateAnexos,
  useUpdatePhotoMutation,
  useUploadAttachmentsMutation,
  useUploadPhotosMutation,
} from '../../../../../../modules/reports/mutations'
import { getStoredFileUrl, showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute, routes } from '../../../../../../services/Routing'
import Utils from '../../../../../../services/Utils'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'
import { DropZone } from './components/dropZone/DropZone'
import { UploadButton } from './components/uploadButton/UploadButton'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StyledInput = styled(Input)`
  width: 260px;
`

const Files = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
`

const File = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  margin: 18px 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  position: relative;

  ${StyledInput} input {
    border: none;
  }
`

const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  gap: 6px;
  font-weight: 400;
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: ${({ theme }) => transparentize(0.2, theme?.colors?.negative?.main)};
  padding: 6px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`

const FilePreview = styled(ExternalLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 16 / 9;
  background-color: ${({ theme }) => transparentize(0.8, theme?.colors?.ntrl_light?.main)};
  outline: none;
`

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  transition: transform 0.3s ease-out;
`

const EditorLink = styled.button`
  outline: none;
  aspect-ratio: 16 / 9;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &:hover {
    ${ImagePreview} {
      transform: scale(1.2);
    }
  }
`

const LoadingModal = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${({ theme }) => transparentize(0.3, theme.colors.white.main)};
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
`

const AutoHeightModal = styled(Modal)`
  &&&.ReactModal__Content {
    height: auto;
    max-height: 80%;
  }
`

const SortButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 6px;
  padding: 3px 12px;
  height: auto;
  margin-top: 12px;
`

const prepareCaptions = ({ anexos, data }) => {
  return {
    attachments: data.attachments.reduce((all, caption, idx) => {
      const id = (anexos?.attachments ?? [])?.[idx]?.id
      if (!id) return all

      all[id] = { caption: typeof caption === 'string' ? caption : '' }
      return all
    }, {}),
    photos: data.photos.reduce((all, caption, idx) => {
      const id = (anexos?.photos ?? [])?.[idx]?.id
      if (!id) return all

      all[id] = { caption: typeof caption === 'string' ? caption : '' }
      return all
    }, {}),
  }
}

const STEP = 'anexos'
export const AnexosScreen = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const [{ isOpen }, { showModal, closeModal }] = useModal()
  const [
    { isOpen: isEditorOpened, params: editorParams },
    { showModal: showAutoHeightModal, closeModal: closeAutoHeightModal },
  ] = useModal()
  const { saveData } = useAutoSave()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const { data: anexos, isLoading: isLoadingAnexos } = useStoredAnexos(reportId)
  const { register, getValues, setValue, handleSubmit, formState } = useForm({
    defaultValues: {
      uploaded: {
        attachments: [],
        photos: [],
      },
    },
    mode: 'onBlur',
  })
  const isDefensaJuridica = useIsDefensaJuridica()

  const addAnexosMutation = useAddAnexosMutation()
  const uploadPhotosMutation = useUploadPhotosMutation()
  const uploadAttachmentsMutation = useUploadAttachmentsMutation()
  const updateAnexosMutation = useUpdateAnexos()
  const removeStoredAttachment = useRemoveStoredAttachment()
  const updatePhotoMutation = useUpdatePhotoMutation()

  const handleSaveCaptions = React.useCallback(
    async ({ data }) => {
      await updateAnexosMutation.mutateAsync({
        reportId: report?.id,
        anexos: prepareCaptions({ anexos, data }),
      })
    },
    [anexos, report?.id, updateAnexosMutation],
  )

  const handleFormChange = React.useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    const { uploaded } = getValues()
    saveData({ id: STEP, reportId, data: prepareCaptions({ anexos, data: uploaded }) })
  }, [anexos, getValues, isClosed, reportId, saveData])

  const handleUploadAttachments = (files) => {
    showModal()
    uploadAttachmentsMutation.mutate(
      {
        reportId: report?.id,
        attachments: Array.from(files).map((file) => {
          return {
            file,
          }
        }),
        attachmentsCaptions: Array.from(files).map((file) => file.name.substring(0, file.name.lastIndexOf('.'))),
      },
      {
        onSettled: () => {
          closeModal()
        },
      },
    )
  }

  const handleUploadPhotos = (files) => {
    showModal()
    uploadPhotosMutation.mutate(
      {
        reportId: report?.id,
        photos: Array.from(files).map((file) => {
          return {
            file,
          }
        }),
        photosCaptions: Array.from(files).map(() => ''),
      },
      {
        onSettled: () => {
          closeModal()
        },
      },
    )
  }

  const handleEditImage = ({ attachmentId, base64 }) => {
    const blob = Utils.base64ToBlob(base64)
    return new Promise((resolve) => {
      updatePhotoMutation.mutate(
        {
          reportId: report?.id,
          attachmentId: attachmentId,
          photo: blob,
        },
        {
          onSettled: () => {
            resolve()
          },
        },
      )
    })
  }

  const {
    open: openImagesModal,
    getRootProps: getImagesRootProps,
    getInputProps: getImagesInputProps,
    isDragActive: isImagesDragActive,
  } = useDropzone({ noClick: true, noKeyboard: true, accept: { 'image/*': [] }, onDrop: handleUploadPhotos })

  const {
    open: openAttachmentsModal,
    getRootProps: getAttachmentsRootProps,
    getInputProps: getAttachmentsInputProps,
    isDragActive: isAttachmentsDragActive,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: { 'application/pdf': ['.pdf'] },
    onDrop: handleUploadAttachments,
  })

  const handleGoBack = useCallback(() => {
    !isClosed &&
      addAnexosMutation.mutate({
        reportId: report?.id,
        ...getValues(),
      })
    navigate(-1)
  }, [isClosed, addAnexosMutation, report?.id, getValues, navigate])

  const onSubmit = (values) => {
    const path = getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente })
    if (isClosed) {
      !!path && navigate(generatePath(path, { reportId }))
      return
    }
    return new Promise(async (resolve, reject) => {
      const { uploaded } = values
      handleSaveCaptions({ data: uploaded }).then(resolve).catch(reject)
    }).then(() => {
      !!path && navigate(generatePath(path, { reportId }))
    })
  }

  React.useEffect(() => {
    if (!anexos) return

    setValue(
      'uploaded.attachments',
      (anexos?.attachments ?? []).map(({ caption }, idx) => {
        return caption ?? ''
      }),
    )

    setValue(
      'uploaded.photos',
      (anexos?.photos ?? []).map(({ caption }, idx) => {
        return caption ?? ''
      }),
    )
  }, [anexos, setValue])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Fotografías
        </Headline>

        <Text variant="black">Añade las fotografías que consideres para detallar el siniestro.</Text>

        {!isLoadingAnexos && anexos?.photos?.length > 0 ? (
          <SortButton
            type="button"
            bordered
            onClick={() => {
              navigate(generatePath(routes.anexosSort, { reportId }), {
                state: {
                  type: 'photos',
                },
              })
            }}
          >
            <CgReorder size={18} color={theme.colors.primary.main} />
            Ordenar
          </SortButton>
        ) : null}
      </Header>

      {isLoadingAnexos ? (
        <Alert variant="info" headline="Cargando..." body="Estamos recuperando los archivos vinculados al expediente" />
      ) : null}

      <Files {...getImagesRootProps()}>
        {isImagesDragActive && <DropZone headline="Suelta aquí tus imágenes para añadirlas al informe" />}

        {anexos?.photos
          ? anexos.photos.map((item, idx) => {
              return (
                <File key={item?.id}>
                  <EditorLink
                    type="button"
                    onClick={() => {
                      showAutoHeightModal({
                        caption: getValues()?.uploaded?.photos?.[idx] ?? '',
                        attachmentId: item.id,
                        url: getStoredFileUrl({ reportId, fileId: item.id }),
                      })
                    }}
                  >
                    <ImagePreview src={getStoredFileUrl({ reportId, fileId: item.id })} />
                  </EditorLink>
                  <StyledInput {...register(`uploaded.photos.${idx}`)} placeholder="Escribe aquí" type="text" />

                  <RemoveButton
                    title="Eliminar"
                    type="button"
                    variant="transparent"
                    onClick={() => {
                      removeStoredAttachment.mutate({
                        reportId,
                        attachmentId: item.id,
                        key: 'photos',
                      })
                    }}
                  >
                    <BsTrash3 size={18} color={theme?.colors?.white?.main} />
                  </RemoveButton>
                </File>
              )
            })
          : null}
        {!isClosed && (
          <File>
            <UploadButton onClick={openImagesModal} />
            <input {...getImagesInputProps()} />
          </File>
        )}
      </Files>

      <Header>
        <Headline size="l" weight="500" variant="black">
          Anexos
        </Headline>

        <Text variant="black">Añade los documentos que consideres necesarios.</Text>

        {!isLoadingAnexos && anexos?.attachments?.length > 0 ? (
          <SortButton
            type="button"
            bordered
            onClick={() => {
              navigate(generatePath(routes.anexosSort, { reportId }), {
                state: {
                  type: 'attachments',
                },
              })
            }}
          >
            <CgReorder size={18} color={theme.colors.primary.main} />
            Ordenar
          </SortButton>
        ) : null}
      </Header>

      <Files {...getAttachmentsRootProps()}>
        {isAttachmentsDragActive && <DropZone headline="Suelta aquí tus documentos para añadirlos al informe" />}

        {anexos?.attachments
          ? anexos.attachments.map((item, idx) => {
              return (
                <File key={item?.id}>
                  <FilePreview>
                    <BsFileEarmarkMedical size={20} color={theme?.colors?.primary?.main} />
                  </FilePreview>
                  <StyledInput {...register(`uploaded.attachments.${idx}`)} placeholder="Escribe aquí" type="text" />

                  <RemoveButton
                    title="Eliminar"
                    type="button"
                    variant="transparent"
                    onClick={() => {
                      removeStoredAttachment.mutate({
                        reportId,
                        attachmentId: item.id,
                        key: 'attachments',
                      })
                    }}
                  >
                    <BsTrash3 size={20} color={theme?.colors?.white?.main} />
                  </RemoveButton>
                </File>
              )
            })
          : null}
        {!isClosed && (
          <File>
            <UploadButton onClick={openAttachmentsModal} />
            <input {...getAttachmentsInputProps()} />
          </File>
        )}
      </Files>

      <Actions
        isSubmitting={formState.isSubmitting}
        nextLabel={isDefensaJuridica ? 'Guardar' : 'Siguiente'}
        onBackClick={handleGoBack}
      />

      {isOpen && (
        <LoadingModal>
          <CircleLoader variant="primary" />
          <Text align="center" variant="black">
            Estamos subiendo los ficheros
          </Text>
        </LoadingModal>
      )}

      <AutoHeightModal isOpen={isEditorOpened} onRequestClose={closeAutoHeightModal}>
        <ImageEditor {...editorParams} onConfirm={handleEditImage} onRequestClose={closeAutoHeightModal} />
      </AutoHeightModal>

      <Comments step={STEP} />
    </Container>
  )
}
