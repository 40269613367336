import Utils from './Utils'
import { matchPath } from 'react-router-dom'

const reportStepsRoutes = Object.freeze({
  informacionGeneral: '/creation/:reportId/c/r/informacion-general',
  destinoDelRiesgo: '/creation/:reportId/c/r/destino-del-riesgo',
  tipoDeSiniestro: '/creation/:reportId/c/r/tipo-de-siniestro',
  medidasDeSeguridad: '/creation/:reportId/c/r/medidas-de-seguridad',
  analisisDeRiesgo: '/creation/:reportId/c/r/analisis-de-riesgo',
  responsables: '/creation/:reportId/c/r/responsables',
  perjudicados: '/creation/:reportId/c/r/perjudicados',
  descripcion: '/creation/:reportId/c/r/descripcion',
  analisisDeCapitales: '/creation/:reportId/c/r/analisis-de-capitales',
  valoracion: '/creation/:reportId/c/r/valoracion',
  propuestaFinal: '/creation/:reportId/c/r/propuesta-final',
  anexos: '/creation/:reportId/c/r/anexos',
  factura: '/creation/:reportId/c/r/factura',
})

export const routes = Object.freeze({
  home: '/creation/:reportId',
  clone: '/creation/:reportId/clone',
  closed: '/creation/:reportId/closed',
  warning: '/creation/:reportId/hands-up',

  ...reportStepsRoutes,
  addResponsable: '/creation/:reportId/c/r/responsables/add',
  addPerjudicado: '/creation/:reportId/c/r/perjudicados/add',
  addValoracion: '/creation/:reportId/c/r/valoracion/add',
  capitales: '/creation/:reportId/c/r/capitales',
  anexosSort: '/creation/:reportId/c/r/anexos/sort',

  reportDetails: '/report/:reportId/detalles',
  reportPreview: '/report/:reportId/preview',
  version: '/version',

  tipoExpediente: '/:reportId/tipo-expediente',

  login: '/login',
  reportCreation: '/:reportId/generation',
  reportClonation: '/:reportId/clonation',
  error: '/:reportId/error',
})

const MAP_TIPO_EXPEDIENTE_ROUTING = {
  [Utils.TIPO_EXPEDIENTE.DANOS]: Object.values(reportStepsRoutes),
  [Utils.TIPO_EXPEDIENTE.DEF_JURIDICA]: [
    reportStepsRoutes.informacionGeneral,
    reportStepsRoutes.responsables,
    reportStepsRoutes.descripcion,
    reportStepsRoutes.valoracion,
    reportStepsRoutes.anexos,
  ],
}

export function getNextRoute({ currentRoute, tipoExpediente }) {
  const routes = MAP_TIPO_EXPEDIENTE_ROUTING[tipoExpediente]
  const currentRouteIndex = routes.findIndex((path) => matchPath(path, currentRoute))
  const nextRouteIndex = currentRouteIndex + 1
  if (nextRouteIndex > routes.length) {
    return null
  }
  return routes[nextRouteIndex]
}
