import React from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi'
import { generatePath } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { routes } from '../../../services/Routing'
import { ButtonFlat } from '../../atoms/buttonFlat/ButtonFlat'
import Card from '../../atoms/card/Card'
import Chip from '../../atoms/chip/Chip'

const RelatedReportCard = styled(Card)`
  position: relative;
  padding: 12px;
  max-width: 300px;
`

const StyledCardTitle = styled(Card.Title)`
  display: flex;
  align-items: center;
  gap: 3px;
`

const Chips = styled.div`
  display: flex;
  gap: 6px;
`

export const ReportCard = React.memo(({ report }) => {
  const theme = useTheme()

  if (!report) {
    return null
  }

  const isClosed = Number(report.cerrado) === 1
  return (
    <RelatedReportCard
      key={report.id}
      onClick={() => {
        generatePath(routes.informacionGeneral, { reportId: report.id })
      }}
    >
      <StyledCardTitle>{report.numero_siniestro}</StyledCardTitle>
      <Chips>
        <Chip>
          {isClosed ? <HiOutlineLockClosed size={14} /> : <HiOutlineLockOpen size={14} />}
          {isClosed ? 'Bloqueado' : 'Abierto'}
        </Chip>
        {!report.hasReportData ? <Chip>Sin datos</Chip> : null}
      </Chips>
      <Card.Body>
        Creado el {new Date(report.created).toLocaleDateString()} a las {new Date(report.created).toLocaleTimeString()}
      </Card.Body>

      <Card.Actions>
        <ButtonFlat
          as="a"
          variant="info"
          rel="noopener noreferrer"
          target="_blank"
          href={generatePath(routes.home, { reportId: report.id })}
          title="Abrir expediente"
        >
          <BiLinkExternal size={14} color={theme?.colors?.white?.main} />
          Abrir
        </ButtonFlat>
      </Card.Actions>
    </RelatedReportCard>
  )
})
