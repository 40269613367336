import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Checkbox, Text } from '../../../../../../components'
import { CheckboxGroup } from '../../../../../../components/molecules/checkboxGroup/CheckboxGroup'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute } from '../../../../../../services/Routing'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const STEP = 'medidasSeguridad'
export const MedidasDeSeguridadScreen = () => {
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      medidasSeguridad_puertaBlindada: report?.medidasSeguridad_puertaBlindada ?? false,
      medidasSeguridad_Alarma: report?.medidasSeguridad_Alarma ?? false,
      medidasSeguridad_lunasBlindada: report?.medidasSeguridad_lunasBlindada ?? false,
      medidasSeguridad_puertaAcorazada: report?.medidasSeguridad_puertaAcorazada ?? false,
      medidasSeguridad_Vigilancia: report?.medidasSeguridad_Vigilancia ?? false,
      medidasSeguridad_cierresMetalicos: report?.medidasSeguridad_cierresMetalicos ?? false,
      medidasSeguridad_Rejas: report?.medidasSeguridad_Rejas ?? false,
      medidasSeguridad_SinProteccionesEspeciales: report?.medidasSeguridad_SinProteccionesEspeciales ?? false,
    },
    mode: 'onBlur',
  })
  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Selecciona las medidas de seguridad
        </Headline>

        <Text variant="black">Marca todas las medidas de seguridad que tenga el asegurado.</Text>
      </Header>

      <CheckboxGroup>
        <Checkbox {...register('medidasSeguridad_puertaBlindada')}>Puerta blindada</Checkbox>

        <Checkbox {...register('medidasSeguridad_Alarma')}>Alarma</Checkbox>

        <Checkbox {...register('medidasSeguridad_lunasBlindada')}>Lunas blindadas</Checkbox>

        <Checkbox {...register('medidasSeguridad_puertaAcorazada')}>Puerta acorazada</Checkbox>

        <Checkbox {...register('medidasSeguridad_Vigilancia')}>Vigilancia</Checkbox>

        <Checkbox {...register('medidasSeguridad_cierresMetalicos')}>Cierres metálicos</Checkbox>

        <Checkbox {...register('medidasSeguridad_Rejas')}>Rejas</Checkbox>

        <Checkbox {...register('medidasSeguridad_SinProteccionesEspeciales')}>Sin protecciones especiales</Checkbox>
      </CheckboxGroup>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
