import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { Editor, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const ItalicText = styled(Text)`
  font-style: italic;
  margin-bottom: 12px;
`

export const DefensaJuridicaForm = ({ report, step, onFormChange, onGoBack, onSubmit }) => {
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      descripcionSiniestro_DaniosProducidos: report?.descripcionSiniestro_DaniosProducidos ?? '',
      descripcionSiniestro_CausaDelSiniestro: report?.descripcionSiniestro_CausaDelSiniestro ?? '',
      descripcionSiniestro_Conclusiones: report?.descripcionSiniestro_Conclusiones ?? '',
    },
    mode: 'onBlur',
  })

  const handleFormChange = () => {
    onFormChange?.({ values: getValues() })
  }

  const handleGoBack = useCallback(() => {
    onGoBack?.({ values: getValues() })
  }, [onGoBack, getValues])

  const {
    descripcionSiniestro_DaniosProducidos,
    descripcionSiniestro_CausaDelSiniestro,
    descripcionSiniestro_Conclusiones,
  } = getValues()

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Descripción del siniestro
        </Headline>
      </Header>

      <Box>
        <Text variant="black" weight="600">
          Descripción de los daños producidos
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_DaniosProducidos}
          onChange={(value) => {
            setValue('descripcionSiniestro_DaniosProducidos', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Descripción de las causas del siniestro
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_CausaDelSiniestro}
          onChange={(value) => {
            setValue('descripcionSiniestro_CausaDelSiniestro', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Conclusiones
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_Conclusiones}
          onChange={(value) => {
            setValue('descripcionSiniestro_Conclusiones', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={step} />
    </Container>
  )
}
