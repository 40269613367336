import React from 'react'
import { generatePath, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Text } from '../../../components'
import { MAP_DOWNLOAD_OPTIONS } from '../../../hooks/useDownload'
import { routes } from '../../../services/Routing'
import socketManager from '../../../services/SocketManager'
import Utils from '../../../services/Utils'
import { Headline } from '../../dashboard/creation/components/headline'
import StepperList from '../../../components/molecules/stepperList/StepperList'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 60px;
`

const Content = styled.div`
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

const STEPS = [
  {
    id: '0',
    title: 'Comenzando la generación del informe',
  },
  {
    id: '1',
    title: 'Generando HTML',
  },
  {
    id: '2',
    title: 'Añadiendo Anexos',
  },
  {
    id: '3',
    title: 'Componiendo PDF final',
  },
  {
    id: '4',
    title: 'Reduciendo el tamaño del PDF',
  },
  {
    id: '5',
    title: 'Descargando el informe',
  },
]

export const ReportGenerationScreen = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const location = useLocation()
  const state = location.state
  const isWorking = React.useRef(false)
  const [currentStep, setCurrentStep] = React.useState(0)

  React.useEffect(() => {
    if (isWorking.current || !reportId || !state?.downloadOption) {
      return
    }

    isWorking.current = true
    socketManager
      .connect()
      .then(() => {
        socketManager.createReport({
          data: {
            idExpediente: Number(reportId),
            pages: MAP_DOWNLOAD_OPTIONS[state?.downloadOption],
            format: state?.format ?? 'pdf',
          },
          onReportReady: async (data) => {
            await socketManager.disconnect()
            const blob = new Blob([data.pdf], { type: 'application/pdf' })
            const filename = data.fileName
            Utils.downloadPDF({ blob, filename })
            setCurrentStep(Math.floor(Number(STEPS.length)))
            setTimeout(() => {
              navigate(generatePath(routes.home, { reportId }), { replace: true })
            }, 2000)
          },
          onProgress: (data) => {
            const { step } = data
            setCurrentStep(Math.floor(Number(step)))
          },
          onError: async () => {
            await socketManager.disconnect()
            toast.error('Ha ocurrido un error y no hemos podido generar el informe')
            navigate(generatePath(routes.error, { reportId }), { replace: true })
          },
        })
      })
      .catch(async () => {
        toast.error('Ha ocurrido un error y no hemos podido generar el informe')
        await socketManager.disconnect()
        navigate(generatePath(routes.error, { reportId }), { replace: true })
      })
  }, [navigate, reportId, state?.downloadOption, state?.format])

  if (!state?.downloadOption) {
    return <Navigate to={generatePath(routes.home, { reportId })} replace />
  }

  return (
    <Container>
      <Content>
        <Headline size="l" weight="500" variant="black">
          Preparando tu informe
        </Headline>
        <Text variant="black" align="center">
          Estamos generando tu informe, por favor, no abandones esta página. El proceso puede tardar unos minutos.
        </Text>
      </Content>

      <StepperList steps={STEPS} activeIndex={currentStep} />
    </Container>
  )
}
