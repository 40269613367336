import React from 'react'
import styled, { keyframes } from 'styled-components'

const StepperContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
`

const Step = styled.div`
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isActive || props.isCompleted ? 1 : 0.5)};
  transition: opacity 0.3s ease;
`

const StepIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.isCompleted) return props.theme.colors.positive.main
    if (props.isActive) return props.theme.colors.primary.main
    return '#ccc'
  }};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: white;
  font-size: 14px;
`

const StepContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const StepTitle = styled.h3`
  font-size: 16px;
  margin: 0;
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.black.main
      : props.isCompleted
      ? props.theme.colors.positive.main
      : props.theme.colors.ntrl_dark.main};
`

const StepDescription = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${(props) => (props.isActive || props.isCompleted ? '#666' : '#999')};
`

const StepConnector = styled.div`
  width: 2px;
  height: 60px;
  background-color: ${(props) =>
    props.isCompleted ? props.theme.colors.positive.main : props.theme.colors.ntrl_light.main};
  margin-left: 11px;
  transition: background-color 0.3s ease;
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`

const CheckMark = styled.span`
  &::after {
    content: '✓';
  }
`

const StepperList = ({ steps, activeIndex }) => {
  return (
    <StepperContainer>
      {steps.map((step, index) => {
        const isCompleted = index < activeIndex
        const isActive = index === activeIndex

        return (
          <React.Fragment key={index}>
            <Step isActive={isActive} isCompleted={isCompleted}>
              <StepIcon isActive={isActive} isCompleted={isCompleted}>
                {isCompleted ? <CheckMark /> : isActive ? <Loader /> : null}
              </StepIcon>
              <StepContent>
                <StepTitle isActive={isActive} isCompleted={isCompleted}>
                  {step.title}
                </StepTitle>
                {step.description ? (
                  <StepDescription isActive={isActive} isCompleted={isCompleted}>
                    {step.description}
                  </StepDescription>
                ) : null}
              </StepContent>
            </Step>
            {index < steps.length - 1 && <StepConnector isCompleted={isCompleted} />}
          </React.Fragment>
        )
      })}
    </StepperContainer>
  )
}

export default StepperList
