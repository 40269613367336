import { useQuery } from '@tanstack/react-query'
import qs from 'qs'
import React from 'react'
import { useParams } from 'react-router-dom'
import url from 'url'
import RequestHandler from '../../../request'
import { useUpdateReportMutation } from '../../mutations'
import {
  fetchAnalisisDeCapitales,
  fetchRelatedReports,
  fetchReport,
  fetchReports,
  fetchStoredAnexos,
  fetchStoredComments,
  fetchStoredReport,
  getCapitales,
  getReportStatus,
} from '../../queries'
import Utils from '../../../../services/Utils'

export const useReports = (options) => {
  return useQuery(['reports'], () => fetchReports(), options)
}

export const useReportById = (reportId, options) => {
  return useQuery(['reports', reportId], () => fetchReport(reportId), options)
}

export const useRelatedReports = (reportId, options) => {
  return useQuery(['reports', reportId, 'related'], () => fetchRelatedReports(reportId), options)
}

export const useAnalisisDeCapitales = (reportId, options) => {
  return useQuery(['reports', reportId, 'analisisCapitales'], () => fetchAnalisisDeCapitales(reportId), options)
}

export const useStoredReport = (reportId, options = {}) => {
  return useQuery(['reports', reportId, 'storedReport'], () => fetchStoredReport(reportId), {
    enabled: !!reportId,
    ...options,
  })
}

export const useStoredComments = (reportId, options = {}) => {
  return useQuery(['reports', reportId, 'storedComments'], () => fetchStoredComments(reportId), {
    enabled: !!reportId,
    ...options,
  })
}

export const useStoredAnexos = (reportId, options = {}) => {
  return useQuery(['reports', reportId?.toString(), 'storedAnexos'], () => fetchStoredAnexos(reportId), {
    enabled: !!reportId,
    ...options,
  })
}

export const useReportStatus = (reportId, options = {}) => {
  return useQuery(['reports', reportId?.toString(), 'status'], () => getReportStatus({ reportId }), {
    enabled: !!reportId,
    ...options,
  })
}

export const useReportCapitales = (reportId, options = {}) => {
  return useQuery(['reports', reportId?.toString(), 'capitales'], () => getCapitales({ reportId }), {
    enabled: !!reportId,
    ...options,
  })
}

export const useIsClosed = (reportId, options = {}) => {
  const { data: status } = useReportStatus(reportId, options)
  return status?.isClosed === true
}

export const useSaveReport = () => {
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const isClosed = useIsClosed(reportId)
  const updateStoredReportMutation = useUpdateReportMutation()

  const handleSave = React.useCallback(
    ({ data, onSuccess, onError }) => {
      if (isClosed) {
        return onError?.({ message: 'No se puede editar un expediente cerrado' })
      }

      updateStoredReportMutation.mutate(
        {
          ...report,
          ...data,
        },
        {
          onSuccess,
          onError,
        },
      )
    },
    [isClosed, report, updateStoredReportMutation],
  )

  return handleSave
}

export const useMontesPeritosExpedienteName = () => {
  const { reportId } = useParams()
  const { data: report, isLoading } = useReportById(reportId)

  return isLoading ? 'Cargando...' : `Expediente ${report?.numero_siniestro}` ?? ''
}

export const useReportPreviewUrl = () => {
  const { _authtoken } = RequestHandler.sharedInstance()
  const { reportId } = useParams()

  return url.resolve(
    window.MontesPeritos.config.REACT_APP_BASE_HOST,
    `expedientes/${reportId}/reportes?${qs.stringify({
      pages: {
        informe: true,
        descripcionSiniestro: true,
        analisisCapitales: true,
        valoracion: true,
        propuesta: true,
        fotografias: true,
        attachments: true,
      },
      format: 'html',
      access_token: _authtoken,
    })}`,
  )
}

export const useIsDefensaJuridica = () => {
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)

  return report?.tipoExpediente === Utils.TIPO_EXPEDIENTE.DEF_JURIDICA
}
