import update from 'immutability-helper'
import { transparentize } from 'polished'
import React from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { BsFileEarmarkMedical } from 'react-icons/bs'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import { Button, Text } from '../../../../../../components'
import { useStoredAnexos } from '../../../../../../modules/reports/hooks/useReports'
import { useUpdateAnexosOrder } from '../../../../../../modules/reports/mutations'
import { getStoredFileUrl } from '../../../../../../modules/reports/utils'
import { routes } from '../../../../../../services/Routing'
import { Headline } from '../../../components/headline'
import { ItemTypes } from './ItemTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 30px 24px;
  width: 100%;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Content = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
`

const ItemContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  margin: 18px 0;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0;
    `}
`

const ItemPreview = styled.img`
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  pointer-events: none;
`

const FilePreview = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => transparentize(0.8, theme?.colors?.ntrl_light?.main)};
`

const ItemHeadline = styled(Text)`
  padding: 6px 12px;
`

const Actions = styled.div`
  display: flex;
  gap: 12px;
`

const Item = React.memo(({ id, type = 'photos', preview, headline, moveCard, findCard }) => {
  const originalIndex = findCard(id).index
  const theme = useTheme()

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id, originalIndex },
      canDrag: true,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveCard(droppedId, originalIndex)
        }
      },
    }),
    [id, originalIndex, moveCard],
  )

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id)
          moveCard(draggedId, overIndex)
        }
      },
    }),
    [findCard, moveCard],
  )

  const isFile = type !== 'photos'
  return (
    <ItemContainer ref={(node) => drag(drop(node))} isDragging={isDragging}>
      {isFile ? (
        <FilePreview>
          <BsFileEarmarkMedical size={20} color={theme?.colors?.primary?.main} />
        </FilePreview>
      ) : (
        <ItemPreview src={preview} />
      )}
      <ItemHeadline variant="black">{!!headline ? headline : 'Sin nombre'}</ItemHeadline>
    </ItemContainer>
  )
})

const AnexosSortList = () => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const location = useLocation()
  const type = location.state?.type ?? 'photos'
  const { data: anexos } = useStoredAnexos(reportId)
  const updateAnexosOrdersMutation = useUpdateAnexosOrder()
  const [sortedData, setSortedData] = React.useState(anexos?.[type] ?? [])
  const [isWorking, setIsWorking] = React.useState(false)

  const handleUpdateAnexosOrder = ({ key, order }) => {
    return new Promise((resolve, reject) => {
      updateAnexosOrdersMutation.mutate(
        {
          reportId,
          key,
          order,
        },
        {
          onSuccess: resolve,
          onError: reject,
        },
      )
    })
  }

  const findCard = React.useCallback(
    (id) => {
      const card = sortedData.filter((c) => `${c.id}` === id)[0]
      return {
        card,
        index: sortedData.indexOf(card),
      }
    },
    [sortedData],
  )
  const moveCard = React.useCallback(
    (id, atIndex) => {
      const { card, index } = findCard(id)
      setSortedData(
        update(sortedData, {
          $splice: [
            [index, 1],
            [atIndex, 0, card],
          ],
        }),
      )
    },
    [findCard, sortedData, setSortedData],
  )
  const [, drop] = useDrop(() => ({ accept: ItemTypes.CARD }))

  const handleConfirm = async () => {
    setIsWorking(true)
    const newOrder = sortedData.map((card) => card.id)
    try {
      await handleUpdateAnexosOrder?.({ key: type, order: newOrder }).catch((error) => {
        console.error(error)
        setIsWorking(false)
      })
      setIsWorking(false)
      navigate(-1)
    } catch (error) {
      // TODO add toast
      console.error(error)
      setIsWorking(false)
    }
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  React.useEffect(() => {
    if (!anexos) {
      navigate(generatePath(routes.anexos, { reportId }))
    }
  }, [anexos, navigate, reportId])

  return (
    <Container ref={drop}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Reorganiza tus archivos
        </Headline>

        <Text variant="black">Arrastra los archivos para reorganizarlos en el orden que quieras</Text>
      </Header>
      <Content>
        {sortedData.map((card) => (
          <Item
            key={card.id}
            id={`${card.id}`}
            type={type}
            preview={getStoredFileUrl({ reportId, fileId: card.id })}
            headline={typeof card.caption === 'string' ? card.caption : ''}
            moveCard={moveCard}
            findCard={findCard}
          />
        ))}
      </Content>

      <Actions>
        <Button bordered type="button" variant="ntrl_dark" onClick={handleGoBack}>
          Atrás
        </Button>

        <Button disabled={isWorking} onClick={handleConfirm}>
          {isWorking ? 'Guardando...' : 'Guardar'}
        </Button>
      </Actions>
    </Container>
  )
}

export const AnexosSortScreen = React.memo((props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <AnexosSortList {...props} />
    </DndProvider>
  )
})
