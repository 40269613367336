import { Duration } from 'luxon'
import Dinero from 'dinero.js'

function humanFileSize(size) {
  if (typeof size === 'undefined') {
    return ' - '
  }
  var i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(0) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

function truncate(text, startChars, endChars, maxLength) {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars)
    var end = text.substring(text.length - endChars, text.length)
    return start + '...' + end
  }
  return text
}

function copyToClipboard(text) {
  return new Promise((resolve) => {
    const fallback = () => {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '-10000px'
      textArea.style.left = '-10000px'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      document.execCommand('copy')
      resolve()
    }

    if (!navigator.clipboard) {
      return fallback()
    }

    return navigator.clipboard.writeText(text).then(resolve)
  })
}

const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
}

function humanTime(seconds) {
  if (typeof seconds === 'undefined') {
    return ' - '
  }
  return Duration.fromObject({ seconds })
}

function getUnitAndValueDate(secondsElapsed) {
  // creamos un for of para extraer cada unidad y los segundos en esa unidad del diccionario
  for (const [unit, secondsInUnit] of Object.entries(DATE_UNITS)) {
    // si los segundos que han pasado entre las fechas es mayor a los segundos
    // que hay en la unidad o si la unidad es "second"...
    if (secondsElapsed >= secondsInUnit || unit === 'second') {
      // extraemos el valor dividiendo el tiempo que ha pasado en segundos
      // con los segundos que tiene la unidad y redondeamos la unidad
      // ej: 3800 segundos pasados / 3600 segundos (1 hora) = 1.05 horas
      // Math.floor(1.05) -> 1 hora
      // finalmente multiplicamos por -1 ya que necesitamos
      // la diferencia en negativo porque, como hemos visto antes,
      // así nos indicará el "Hace ..." en lugar del "Dentro de..."
      const value = Math.floor(secondsElapsed / secondsInUnit) * -1
      // además del valor también tenemos que devolver la unidad
      return { value, unit }
    }
  }
}

function getSecondsDiff(timestamp) {
  // restamos el tiempo actual al que le pasamos por parámetro
  // lo dividimos entre 1000 para quitar los milisegundos
  return (Date.now() - timestamp) / 1000
}

function getTimeAgo(timestamp) {
  // creamos una instancia de RelativeTimeFormat para traducir en castellano
  const rtf = new Intl.RelativeTimeFormat({
    localeMatcher: 'best fit',
    numeric: 'always',
    style: 'long',
  })
  // recuperamos el número de segundos de diferencia entre la fecha que pasamos
  // por parámetro y el momento actual
  const secondsElapsed = getSecondsDiff(timestamp)
  // extraemos la unidad de tiempo que tenemos que usar
  // para referirnos a esos segundos y el valor
  const { value, unit } = getUnitAndValueDate(secondsElapsed)
  // formateamos el tiempo relativo usando esos dos valores
  return rtf.format(value, unit)
}

const toAbsoluteAmount = (amount) => {
  return parseInt((parseFloat(amount) * 1000).toFixed(3))
}

function toDinero(amount) {
  return Dinero({ amount: toAbsoluteAmount(amount), currency: 'EUR', precision: 3 })
}

function invariant(cond, message, ...args) {
  if (cond) {
    return
  }

  throw new Error(
    'Internal Lexical error: invariant() is meant to be replaced at compile ' +
      'time. There is no runtime version. Error: ' +
      message,
  )
}

function convertCapitalesdAseguradosToJSON(capitales = '') {
  const matches = capitales.matchAll(/[(][^=]+\s*=\s*[0-9]+.?[0-9]+[)]/gm)
  const json = {}
  for (const match of matches) {
    const sanitized = match[0].trim().replace(/[{()}]/g, '')
    const split = sanitized.split('=')
    json[decodeURIComponent(escape(split[0].trim()))] = parseFloat((split[1] || '0').trim())
  }

  return json
}

function base64ToBlob(base64) {
  // Convert base64 to raw binary data held in a string
  var byteString = atob(base64.split(',')[1])

  // Separate out the mime component
  var mimeString = base64.split(',')[0].split(':')[1].split(';')[0]

  // Write the bytes of the string to an ArrayBuffer
  var arrayBuffer = new ArrayBuffer(byteString.length)
  var _ia = new Uint8Array(arrayBuffer)
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i)
  }

  var dataView = new DataView(arrayBuffer)
  var blob = new Blob([dataView], { type: mimeString })
  return blob
}

function downloadPDF({ blob, filename }) {
  const url = URL.createObjectURL(blob, { type: 'application/pdf' })
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
  a.remove()
  URL.revokeObjectURL(url)
}

const Utils = {
  humanFileSize,
  truncate,
  copyToClipboard,
  humanTime,
  getTimeAgo,
  toAbsoluteAmount,
  toDinero,
  invariant,
  convertCapitalesdAseguradosToJSON,
  base64ToBlob,
  TIPO_EXPEDIENTE: {
    DANOS: 'daños',
    DEF_JURIDICA: 'def_juridica',
  },
  downloadPDF,
}

export default Utils
