import io from 'socket.io-client'
import RequestHandler from '../modules/request'

class SocketManager {
  constructor() {
    if (SocketManager.instance) {
      return SocketManager.instance
    }
    SocketManager.instance = this
    this.socket = null
  }

  connect() {
    return new Promise((resolve, reject) => {
      if (!this.socket) {
        console.info('Connecting socket')
        const { getAuthToken } = RequestHandler.sharedInstance()
        this.socket = io(window.MontesPeritos.config.REACT_APP_BASE_WS, {
          auth: {
            token: getAuthToken(),
          },
        })
        this.socket.on('connect', () => {
          setTimeout(() => {
            console.info('Socket connected')
            resolve()
          }, 2000)
        })

        this.socket.on('connect_error', (error) => {
          console.error('Socket connection error', error)
          this.socket.off()
          this.socket = null
          reject(error)
        })
        return
      }
      resolve()
    })
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.on('disconnect', () => {
          console.info('Socket disconnected')
          this.socket.off()
          this.socket = null
          resolve()
        })

        this.socket.on('error', reject)
        this.socket.disconnect()
        return
      }
      resolve()
    })
  }

  createReport({ data, onReportReady, onProgress, onError }) {
    if (this.socket) {
      console.info('Creating report with data', data)
      this.socket.emit('createReport', data)

      this.socket.once(data.format === 'pdf' ? 'pdfReport' : 'htmlReport', (data) => {
        console.log('Reporte recibido', data)
        this.socket.off('reportProgress', onProgress)
        onReportReady?.(data)
      })

      this.socket.on('reportProgress', onProgress)
      this.socket.once('error', (data) => {
        console.info('Error', data)
        onError?.(data)
      })
    } else {
      console.error('Socket is not connected')
    }
  }

  cloneReport({ data, onReportReady, onProgress, onError }) {
    if (this.socket) {
      this.socket.emit('cloneReport', data)

      this.socket.once('reportCloned', (data) => {
        console.log('Reporte recibido', data)
        this.socket.off('cloneReportProgress', onProgress)
        onReportReady?.(data)
      })

      this.socket.on('cloneReportProgress', onProgress)
      this.socket.once('error', (data) => {
        console.info('Error', data)
        onError?.(data)
      })
    } else {
      console.error('Socket is not connected')
    }
  }
}

// Create and export a single instance
const socketManager = new SocketManager()
export default socketManager
