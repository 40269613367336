import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input } from '../../../../../../../components/atoms/input/Input'
import Utils from '../../../../../../../services/Utils'
import Actions from '../../../components/actions/Actions'
import { Checkbox } from '../../../../../../../components/atoms/checkbox/Checkbox'

const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: visible;
`

const ShortInput = styled(Input)`
  width: 160px;
`

const DescriptionInput = styled(Input)`
  width: 550px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
`

export const AddValoracionDefensaJuridica = ({ onSubmit, onGoBack }) => {
  const location = useLocation()
  const valoracion = location.state?.initialData ?? {}
  const isEditionMode = location.state?.isEditionMode ?? false
  const initialRender = useRef(true)
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      isHeader: valoracion?.isHeader ?? false,
      Descripcion: valoracion?.Descripcion ?? '',
      Unidades: valoracion?.Unidades ?? 0,
      CosteUnidad: valoracion?.CosteUnidad ?? 0,
      TotalPropuesta: valoracion?.TotalPropuesta ?? 0,
    },
    mode: 'onBlur',
  })
  const watchCosteUnidad = watch('CosteUnidad')
  const watchUnidades = watch('Unidades')
  const watchIsHeader = watch('isHeader')

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    let costeUnidad
    try {
      costeUnidad = Utils.toDinero(parseFloat(watchCosteUnidad))
    } catch (error) {
      costeUnidad = Utils.toDinero(0)
    }

    const Unidades = isNaN(parseFloat(watchUnidades)) ? 0 : parseFloat(watchUnidades)
    let Total = costeUnidad.multiply(Unidades)
    setValue('TotalPropuesta', Total.convertPrecision(2).toUnit(), {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [setValue, watchCosteUnidad, watchUnidades])

  useEffect(() => {
    if (watchIsHeader) {
      setValue('Unidades', 0, { shouldValidate: true, shouldDirty: true })
      setValue('CosteUnidad', 0, { shouldValidate: true, shouldDirty: true })
      setValue('TotalPropuesta', 0, { shouldValidate: true, shouldDirty: true })
    }
  }, [setValue, watchIsHeader])

  return (
    <Content onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Checkbox {...register('isHeader')}>¿Es un título?</Checkbox>
      </Row>

      <Row>
        <DescriptionInput
          {...register('Descripcion', { required: true })}
          label="Descripcion"
          placeholder="Escribe aquí"
          type="text"
        />

        {!watchIsHeader ? (
          <>
            <ShortInput
              {...register('Unidades', { required: true })}
              label="UD."
              placeholder="Escribe aquí"
              type="number"
            />
            <ShortInput
              {...register('CosteUnidad', { required: true })}
              label="Coste Ud."
              placeholder="Escribe aquí"
              type="number"
            />
            <ShortInput
              {...register('TotalPropuesta', { required: true })}
              label="Total"
              placeholder="Escribe aquí"
              type="number"
            />
          </>
        ) : null}
      </Row>

      <Actions
        isSubmitting={isSubmitting}
        nextDisabled={!isValid}
        nextLabel={isEditionMode ? 'Guardar' : 'Añadir'}
        onBackClick={onGoBack}
      />
    </Content>
  )
}
