import { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'
import styled, { css } from 'styled-components/macro'
import {
  Button,
  Dropdown,
  DropdownOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { ConfirmationModal } from '../../../../../../components/molecules/modal/ConfirmationModal'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import { useModal } from '../../../../../../hooks/useModal'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute, routes } from '../../../../../../services/Routing'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

const StyledDropdown = styled(Dropdown)`
  top: 60px;
  right: 20px;
  z-index: 99;
`

const StyledDropdownOption = styled(DropdownOption)`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ isDangerAction }) =>
    isDangerAction &&
    css`
      color: ${({ theme }) => theme.colors.negative.main};
    `}
`

const TableWrapper = styled.div`
  /* overflow: auto; */
`

const STEP = 'perjudicados'
export const PerjudicadosScreen = () => {
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const [
    { isOpen: isConfirmationOpened, params: confirmationParams },
    { showModal: showConfirmationModal, closeModal: closeConfirmationModal },
  ] = useModal()
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      perjudicados: report?.perjudicados ?? [],
    },
    mode: 'onBlur',
  })
  const { fields, remove } = useFieldArray({
    control,
    name: 'perjudicados',
  })
  const theme = useTheme()
  const { saveData } = useAutoSave()

  const handleRequestAddPerjudicado = useCallback(
    ({ initialData = {}, isEditionMode = false, idx }) => {
      navigate(generatePath(routes.addPerjudicado, { reportId }), {
        state: { initialData, isEditionMode, idx },
      })
    },
    [navigate, reportId],
  )

  const handleConfirm = () => {
    closeConfirmationModal()
    handleRemovePerjudicado(confirmationParams.idx)
  }

  const handleRemovePerjudicado = (idx) => {
    const values = getValues()
    const perjudicado = values.perjudicados[idx]
    const valoraciones = (report?.valoracionDanios_Table ?? []).filter(
      (valoracion) => valoracion.Perjudicado !== perjudicado.perjudicados_id,
    )
    remove(idx)

    saveData({
      id: STEP,
      reportId,
      data: {
        ...getValues(),
        valoracionDanios_Table: valoraciones,
      },
    })
  }

  const showRemoveActionWarningIfNeeded = (idx) => {
    const values = getValues()
    const perjudicado = values.perjudicados[idx]
    const valoraciones = report?.valoracionDanios_Table ?? []
    const shouldShowWarning = valoraciones.some((valoracion) => valoracion.Perjudicado === perjudicado.perjudicados_id)
    if (!shouldShowWarning) {
      return handleRemovePerjudicado(idx)
    }

    showConfirmationModal({ idx })
  }

  const handleSaveReport = useSaveReport()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Perjudicados
        </Headline>

        <Text variant="black">Añade los datos de cada uno de los afectados en el siniestro.</Text>
      </Header>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Nombre
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Teléfono
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Email
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  NIF
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Domicilio
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Aseguradora
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Poliza
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Expediente
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" weight="500" size="sm">
                  Cuantía Asegurada
                </Text>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {fields.map((item, idx) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_nombre}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_telefono}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_email}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_nif}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_domicilioAfectado}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_aseguradora}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_poliza}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_expediente}
                    </Text>
                  </TableCell>

                  <TableCell>
                    <Text variant="black" size="sm">
                      {item.perjudicados_cuantiaAsegurada}
                    </Text>
                  </TableCell>

                  <TableCell>
                    {!isClosed && (
                      <StyledDropdown>
                        <StyledDropdownOption
                          onClick={() => handleRequestAddPerjudicado({ initialData: item, isEditionMode: true, idx })}
                        >
                          <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                          Editar
                        </StyledDropdownOption>
                        <StyledDropdownOption isDangerAction onClick={() => showRemoveActionWarningIfNeeded(idx)}>
                          <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                          Eliminar
                        </StyledDropdownOption>
                      </StyledDropdown>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              {!isClosed && (
                <TableCell colSpan="9">
                  <AddButton type="button" variant="transparent" onClick={handleRequestAddPerjudicado}>
                    <AiOutlineUsergroupAdd size={20} color={theme?.colors?.secondary?.main} />
                    Añadir perjudicado
                  </AddButton>
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableWrapper>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />

      <RemovePerjudicatoWarningModal
        isOpen={isConfirmationOpened}
        onCancel={closeConfirmationModal}
        onConfirm={handleConfirm}
      />
    </Container>
  )
}

const RemovePerjudicatoWarningModal = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <ConfirmationModal isOpen={isOpen}>
      <ConfirmationModal.Title>¿Estás seguro?</ConfirmationModal.Title>
      <ConfirmationModal.Subtitle>
        Parece que tienes alguna valoración de daños asignada a este perjudicado. Si lo eliminas, se eliminará también
        la valoración de daños asociada.
      </ConfirmationModal.Subtitle>

      <ConfirmationModal.Actions>
        <Button onClick={onCancel}>Cancelar</Button>
        <Button variant="negative" onClick={onConfirm}>
          Eliminar
        </Button>
      </ConfirmationModal.Actions>
    </ConfirmationModal>
  )
}
