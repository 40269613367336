import { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Checkbox, Input, Text } from '../../../../../../components'
import { CheckboxGroup } from '../../../../../../components/molecules/checkboxGroup/CheckboxGroup'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute } from '../../../../../../services/Routing'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const STEP = 'tipoSiniestro'
export const TipoDeSiniestroScreen = () => {
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report } = useStoredReport(reportId)
  const {
    register,
    watch,
    reset,
    getValues,
    resetField,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      tipoSiniestro_Agua: report?.tipoSiniestro_Agua ?? false,
      tipoSiniestro_Robo: report?.tipoSiniestro_Robo ?? false,
      tipoSiniestro_DanioElectrico: report?.tipoSiniestro_DanioElectrico ?? false,
      tipoSiniestro_Metereologico: report?.tipoSiniestro_Metereologico ?? false,
      tipoSiniestro_Incendio: report?.tipoSiniestro_Incendio ?? false,
      tipoSiniestro_ResponsabilidadCivil: report?.tipoSiniestro_ResponsabilidadCivil ?? false,
      tipoSiniestro_Otros: report?.tipoSiniestro_Otros ?? false,
      tipoSiniestro_Especificacion: report?.tipoSiniestro_Especificacion ?? '',
    },
    mode: 'onBlur',
  })
  const watchOtros = watch('tipoSiniestro_Otros', false)
  const watchFields = watch([
    'tipoSiniestro_Agua',
    'tipoSiniestro_Robo',
    'tipoSiniestro_DanioElectrico',
    'tipoSiniestro_Metereologico',
    'tipoSiniestro_Incendio',
    'tipoSiniestro_ResponsabilidadCivil',
  ])
  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(() => {
    if (isClosed) {
      showBlockedReportdWarning()
      return
    }

    saveData({ id: STEP, reportId, data: getValues() })
  }, [getValues, isClosed, reportId, saveData])

  const handleGoBack = useCallback(() => {
    handleSaveReport({ data: getValues() })
    navigate(-1)
  }, [handleSaveReport, getValues, navigate])

  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  useEffect(() => {
    if (watchOtros) {
      reset({
        tipoSiniestro_Agua: false,
        tipoSiniestro_Robo: false,
        tipoSiniestro_DanioElectrico: false,
        tipoSiniestro_Metereologico: false,
        tipoSiniestro_Incendio: false,
        tipoSiniestro_ResponsabilidadCivil: false,
        tipoSiniestro_Otros: true,
        tipoSiniestro_Especificacion: '',
      })
    }
  }, [reset, watchOtros])

  useEffect(() => {
    if (!!watchOtros && watchFields.some((field) => !!field)) {
      resetField('tipoSiniestro_Otros', { defaultValue: false })
      resetField('tipoSiniestro_Especificacion', { defaultValue: '' })
    }
  }, [watchOtros, watchFields, resetField])

  return (
    <Container onChange={handleFormChange} onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Selecciona el tipo de siniestro
        </Headline>

        <Text variant="black">Marca aquellos que apliquen al informe que estas generando.</Text>
      </Header>

      <CheckboxGroup>
        <Checkbox {...register('tipoSiniestro_Agua')}>Agua</Checkbox>

        <Checkbox {...register('tipoSiniestro_Robo')}>Robo/Act. Vand.</Checkbox>

        <Checkbox {...register('tipoSiniestro_DanioElectrico')}>Daños eléctricos</Checkbox>

        <Checkbox {...register('tipoSiniestro_Metereologico')}>Fen. Metereol.</Checkbox>

        <Checkbox {...register('tipoSiniestro_Incendio')}>Incendio</Checkbox>

        <Checkbox {...register('tipoSiniestro_ResponsabilidadCivil')}>Responsabilidad Civil</Checkbox>

        <Checkbox {...register('tipoSiniestro_Otros')}>Otros</Checkbox>
      </CheckboxGroup>

      {watchOtros && (
        <Input
          {...register('tipoSiniestro_Especificacion')}
          label="Especificación"
          placeholder="Escribe aquí"
          type="text"
        />
      )}

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={STEP} />
    </Container>
  )
}
