import { QueryClient } from '@tanstack/react-query'

export const PeritosQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error.response?.status === 403) {
          return false
        }
        const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry

        return Number.isSafeInteger(defaultRetry) ? failureCount < (defaultRetry ?? 0) : false
      },
    },
  },
})
