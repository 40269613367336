import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import Text from '../../../../../../components/atoms/text/Text'
import { useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { useUpdatePartialReportMutation } from '../../../../../../modules/reports/mutations'
import Utils from '../../../../../../services/Utils'
import { Headline } from '../../../components/headline'
import { AddValoracionDanos } from './components/AddValoracionDanos'
import { AddValoracionDefensaJuridica } from './components/AddValoracionDefensaJuridica'

const Container = styled.article`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const AddValoracionScreen = () => {
  const location = useLocation()
  const isEditionMode = location.state?.isEditionMode ?? false
  const editionIdx = location.state?.idx ?? -1
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)

  const updatePartialReportMutation = useUpdatePartialReportMutation()

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      const valoracionDanios_Table = [...(report.valoracionDanios_Table ?? [])]
      if (isEditionMode) {
        valoracionDanios_Table[editionIdx] = values
      } else {
        valoracionDanios_Table.push(values)
      }
      updatePartialReportMutation.mutate(
        {
          id: report.id,
          valoracionDanios_Table,
        },
        {
          onSuccess: () => {
            resolve()
            navigate(-1)
          },
          onError: (error) => {
            reject(error)
            console.info(error)
          },
        },
      )
    })
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <Container>
      <Header>
        <Headline size="l" weight="500" variant="black">
          {isEditionMode ? 'Edita la valoración de daño' : 'Añadir valoración de daños'}
        </Headline>

        <Text variant="black">
          {isEditionMode
            ? 'Actualiza los datos de la valoración de daños'
            : 'Completa la valoración de daños para añadirla al informe.'}
        </Text>
      </Header>

      {report.tipoExpediente === Utils.TIPO_EXPEDIENTE.DANOS ? (
        <AddValoracionDanos onSubmit={onSubmit} onGoBack={handleGoBack} />
      ) : (
        <AddValoracionDefensaJuridica onSubmit={onSubmit} onGoBack={handleGoBack} />
      )}
    </Container>
  )
}
