import { marked } from 'marked'
import * as DOMPurify from 'dompurify'

import ReportsAPI from './api'

export const fetchReports = async () => {
  const response = await ReportsAPI.getReports()
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const fetchReport = async (reportId) => {
  const response = await ReportsAPI.getReport({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }

  return await response.json()
}

export const fetchRelatedReports = async (reportId) => {
  const response = await ReportsAPI.getRelatedReports({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const fetchAnalisisDeCapitales = async (reportId) => {
  const response = await ReportsAPI.getAnalisisCapitales({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const fetchStoredReport = async (reportId) => {
  const response = await ReportsAPI.getStoredReport({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  const report = await response.json()
  if (!report?.id) {
    report.id = reportId
  }

  if (report?.descripcionSiniestro_OrigenYCircunstancias) {
    report.descripcionSiniestro_OrigenYCircunstancias = DOMPurify.sanitize(
      marked.parse(report.descripcionSiniestro_OrigenYCircunstancias ?? ''),
    )
  }

  if (report?.descripcionSiniestro_DaniosReclamados) {
    report.descripcionSiniestro_DaniosReclamados = DOMPurify.sanitize(
      marked.parse(report.descripcionSiniestro_DaniosReclamados ?? ''),
    )
  }

  if (report?.descripcionSiniestro_ConsideracionesPericiales) {
    report.descripcionSiniestro_ConsideracionesPericiales = DOMPurify.sanitize(
      marked.parse(report.descripcionSiniestro_ConsideracionesPericiales ?? ''),
    )
  }

  if (report?.descripcionSiniestro_Conclusiones) {
    report.descripcionSiniestro_Conclusiones = DOMPurify.sanitize(
      marked.parse(report.descripcionSiniestro_Conclusiones ?? ''),
    )
  }

  if (report?.descripcionSiniestro_DescripcionRiesgo) {
    report.descripcionSiniestro_DescripcionRiesgo = DOMPurify.sanitize(
      marked.parse(report.descripcionSiniestro_DescripcionRiesgo ?? ''),
    )
  }

  return report
}

export const updateStoredReport = async (report) => {
  const response = await ReportsAPI.updateStoredReport({ report })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const patchStoredReport = async (report) => {
  const response = await ReportsAPI.patchStoredReport({ report })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const fetchStoredComments = async (reportId) => {
  const response = await ReportsAPI.getStoredComments({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updateStoredComments = async (reportId, comments) => {
  const response = await ReportsAPI.updateStoredComments({ reportId, comments })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const addComment = async ({ reportId, step, comments, comment }) => {
  comments[step] = [...(comments[step] ?? []), comment].filter(Boolean)

  const response = await ReportsAPI.updateStoredComments({ reportId, comments })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const removeComment = async ({ reportId, step, comments, commentIdx }) => {
  const stepComments = [...(comments[step] ?? [])]
  stepComments.splice(commentIdx, 1)
  comments[step] = stepComments.filter(Boolean)

  const response = await ReportsAPI.updateStoredComments({ reportId, comments })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const fetchStoredAnexos = async (reportId) => {
  const response = await ReportsAPI.getStoredAnexos({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const addAnexos = async ({ reportId, attachments, photos, attachmentsCaptions, photosCaptions }) => {
  const response = await ReportsAPI.addAnexos({ reportId, attachments, photos, attachmentsCaptions, photosCaptions })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updateAnexos = async ({ reportId, anexos }) => {
  const response = await ReportsAPI.updateAnexos({ reportId, anexos })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updateAnexosOrder = async ({ reportId, key, order }) => {
  const response = await ReportsAPI.updateAnexosOrder({ reportId, key, order })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const removeStoredAttachment = async ({ reportId, key, attachmentId }) => {
  const response = await ReportsAPI.removeStoredAttachment({ reportId, key, attachmentId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updateStoredAttachment = async ({ reportId, key, attachmentId, caption }) => {
  const response = await ReportsAPI.updateStoredAttachment({ reportId, key, attachmentId, caption })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updatePhoto = async ({ reportId, attachmentId, photo }) => {
  const response = await ReportsAPI.updatePhoto({ reportId, attachmentId, photo })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const closeReport = async ({ reportId }) => {
  const response = await ReportsAPI.closeReport({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const openReport = async ({ reportId }) => {
  const response = await ReportsAPI.openReport({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const getReportStatus = async ({ reportId }) => {
  const response = await ReportsAPI.getReportStatus({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const downloadReport = async ({ reportId, options }) => {
  await ReportsAPI.downloadReport({ reportId, options })
}

export const cloneReport = async ({ from, to }) => {
  const response = await ReportsAPI.cloneReport({ from, to })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const getCapitales = async ({ reportId }) => {
  const response = await ReportsAPI.getCapitales({ reportId })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const updateCapitales = async ({ reportId, capitales }) => {
  const response = await ReportsAPI.updateCapitales({ reportId, capitales })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  const updatedCapitales = await response.json()
  const report = await fetchStoredReport(reportId)

  return { report, capitales: updatedCapitales }
}

export const addCapitales = async ({ reportId, capitales }) => {
  const response = await ReportsAPI.addData({ reportId, key: 'capitales', value: capitales })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const addCapital = async ({ reportId, capital, value }) => {
  const response = await ReportsAPI.editData({ reportId, key: 'capitales', subkey: capital, value })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const editCapital = async ({ reportId, capital, value }) => {
  const response = await ReportsAPI.editData({ reportId, key: 'capitales', subkey: capital, value })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}

export const deleteCapital = async ({ reportId, capital }) => {
  const response = await ReportsAPI.deleteData({ reportId, key: 'capitales', subkey: capital })
  if (response.status !== 200) {
    throw new Error('Bad Request', { cause: response.status })
  }
  return await response.json()
}
