import { useCallback } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useAutoSave } from '../../../../../../hooks/useAutoSave'
import { useCurrentRoute } from '../../../../../../hooks/useCurrentRoute'
import { useIsClosed, useSaveReport, useStoredReport } from '../../../../../../modules/reports/hooks/useReports'
import { showBlockedReportdWarning } from '../../../../../../modules/reports/utils'
import { getNextRoute } from '../../../../../../services/Routing'
import Utils from '../../../../../../services/Utils'
import { DanosForm } from './DanosForm'
import { DefensaJuridicaForm } from './DefensaJuridicaForm'

const STEP = 'descripcionSiniestro'
export const DescripcionScreen = () => {
  const navigate = useNavigate()
  const currentRoute = useCurrentRoute()
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const { data: report, isLoading: isLoadingReport } = useStoredReport(reportId)

  const handleSaveReport = useSaveReport()

  const { saveData } = useAutoSave()

  const handleFormChange = useCallback(
    ({ values }) => {
      if (isClosed) {
        showBlockedReportdWarning()
        return
      }

      saveData({ id: STEP, reportId, data: values })
    },
    [isClosed, reportId, saveData],
  )

  const handleGoBack = useCallback(
    ({ values }) => {
      handleSaveReport({ data: values })
      navigate(-1)
    },
    [handleSaveReport, navigate],
  )

  const onSubmit = (values) => {
    const path = generatePath(getNextRoute({ currentRoute, tipoExpediente: report.tipoExpediente }), { reportId })
    if (isClosed) {
      navigate(path)
      return
    }

    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(path)
        },
        onError: () => {
          reject()
        },
      })
    })
  }

  if (isLoadingReport) {
    return null
  }

  return report.tipoExpediente === Utils.TIPO_EXPEDIENTE.DANOS ? (
    <DanosForm
      report={report}
      step={STEP}
      onSubmit={onSubmit}
      onFormChange={handleFormChange}
      onGoBack={handleGoBack}
    />
  ) : (
    <DefensaJuridicaForm
      report={report}
      step={STEP}
      onSubmit={onSubmit}
      onFormChange={handleFormChange}
      onGoBack={handleGoBack}
    />
  )
}
