import React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Text } from '../../../../components'
import Button from '../../../../components/atoms/button/Button'
import { ReportCard } from '../../../../components/molecules/reportCard/ReportCard'
import { useRelatedReports } from '../../../../modules/reports/hooks/useReports'
import { routes } from '../../../../services/Routing'
import { Headline } from '../components/headline'

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  gap: 18px;
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 18px;
  flex: 1;
  padding: 60px 120px;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 60px 30px;
  }
`

const SideContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: safe center;
  gap: 12px;
  background-color: ${({ theme }) => theme.colors.ntrl_lighter.main};
  padding: 60px 30px;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
`

export const WarningReportScreen = React.memo(() => {
  const { reportId } = useParams()
  const { data: relatedReports } = useRelatedReports(reportId)
  const navigate = useNavigate()

  const handleSkipClick = () => {
    navigate(generatePath(routes.informacionGeneral, { reportId }))
  }

  return (
    <Container>
      <Content>
        <Headline size="l" weight="500" variant="black">
          ¡Un momento!
        </Headline>
        <Text variant="black">
          Parece que hay expedientes posteriores al que estás tratando de acceder. Por favor, revisa si es necesario
          actualizar la información de este expediente o si necesitas acceder a uno de los siguientes expedientes
          relacionados
        </Text>

        <Button bordered type="button" variant="ntrl_dark" onClick={handleSkipClick}>
          Continuar de todos modos
        </Button>
      </Content>
      <SideContent>
        {relatedReports?.map((report) => {
          return <ReportCard key={report.id} report={report} />
        })}
      </SideContent>
    </Container>
  )
})
