import React, { useCallback } from 'react'
import { AiOutlineCopy, AiOutlineDelete, AiOutlineEdit, AiOutlineFolderAdd } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import styled, { css, useTheme } from 'styled-components/macro'
import {
  Button,
  Dropdown,
  DropdownOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../../../../../components'
import { useIsClosed } from '../../../../../../../modules/reports/hooks/useReports'
import Utils from '../../../../../../../services/Utils'
import { ButtonIcon } from '../../../../../../../components/atoms/buttonIcon/ButtonIcon'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi'

const StyledTable = styled(Table)`
  & tr {
    & td:nth-child(2) {
      padding-left: 6px;
    }
  }
`

const TotalTableRow = styled(TableRow)`
  & td {
    padding: 10px 20px;
  }
`

const StyledLeftActionCell = styled(TableCell)`
  width: 60px;
  padding: 10px 0px;
  box-sizing: border-box;

  & > * {
    opacity: 0;
    transition: opacity 0.2s;
  }
`

const LeftActions = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`

const LeftAction = styled(ButtonIcon)`
  padding: 0;
  width: 24px;
  height: 24px;
`

const HoverTableRow = styled(TableRow)`
  &:hover {
    ${StyledLeftActionCell} {
      & > * {
        opacity: 1;
      }
    }
  }
`

const StyledTableRow = styled(HoverTableRow)`
  background-color: ${({ theme }) => `${theme?.colors?.ntrl_lighter?.main}`};
`

const StyledTableCell = styled(TableCell)`
  padding: 10px 20px;
`

const StyledDropdown = styled(Dropdown)`
  top: 60px;
  right: 20px;
  z-index: 99;
`

const StyledDropdownOption = styled(DropdownOption)`
  display: flex;
  align-items: center;
  gap: 6px;

  ${({ isDangerAction }) =>
    isDangerAction &&
    css`
      color: ${({ theme }) => theme.colors.negative.main};
    `}
`

const AddButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  height: initial;
  color: ${({ theme }) => theme.colors.secondary.main};
  gap: 6px;
  font-weight: 400;
`

export const ValoracionDefensaJuridicaTable = ({ valoraciones, onAdd, onMoveUp, onMoveDown, onRemove }) => {
  const { reportId } = useParams()
  const isClosed = useIsClosed(reportId)
  const theme = useTheme()

  const renderTotalRow = useCallback((data) => {
    const { unidades, total } = data.reduce(
      (summary, item) => {
        if (item.isHeader) return summary
        summary.unidades += parseInt(item.Unidades)
        summary.total = summary.total.add(Utils.toDinero(parseFloat(item.TotalPropuesta)))
        return summary
      },
      { unidades: 0, total: Utils.toDinero(0) },
    )
    return (
      <TotalTableRow>
        <TableCell colSpan="2">
          <Text variant="black" size="sm" weight="600">
            Resumen
          </Text>
        </TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {unidades}
          </Text>
        </TableCell>

        <TableCell></TableCell>

        <TableCell>
          <Text variant="black" size="sm">
            {total.convertPrecision(2).toUnit()}
          </Text>
        </TableCell>

        <TableCell></TableCell>
      </TotalTableRow>
    )
  }, [])

  const rows = valoraciones ?? []
  return (
    <StyledTable>
      <TableHead>
        <TableRow>
          <TableCell colSpan="2">
            <Text variant="black" weight="500" size="sm">
              Descripción
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Unidades
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Coste Ud.
            </Text>
          </TableCell>

          <TableCell>
            <Text variant="black" weight="500" size="sm">
              Total
            </Text>
          </TableCell>

          <TableCell></TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {rows.map((item, idx) => {
          const isLastItem = idx === rows.length - 1
          if (item.isHeader) {
            return (
              <StyledTableRow key={item.id}>
                <StyledLeftActionCell>
                  <LeftActions>
                    <LeftAction
                      icon={<FiChevronUp size="24px" color={theme?.colors.primary?.main} />}
                      tabIndex={-1}
                      disabled={idx === 0}
                      onClick={() => onMoveUp?.(idx)}
                      aria-label="Mover arriba"
                      title="Mover arriba"
                    />

                    <LeftAction
                      icon={<FiChevronDown size="24px" color={theme?.colors.primary?.main} />}
                      tabIndex={-1}
                      disabled={isLastItem}
                      onClick={() => onMoveDown?.(idx)}
                      aria-label="Mover abajo"
                      title="Mover abajo"
                    />
                  </LeftActions>
                </StyledLeftActionCell>

                <StyledTableCell>
                  <Text variant="black" size="sm">
                    {item.Descripcion}
                  </Text>
                </StyledTableCell>

                <StyledTableCell></StyledTableCell>

                <StyledTableCell></StyledTableCell>

                <StyledTableCell></StyledTableCell>

                <StyledTableCell>
                  <StyledDropdown>
                    <StyledDropdownOption onClick={() => onAdd?.({ initialData: item, isEditionMode: true, idx })}>
                      <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                      Editar
                    </StyledDropdownOption>
                    <StyledDropdownOption isDangerAction onClick={() => onRemove?.(idx)}>
                      <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                      Eliminar
                    </StyledDropdownOption>
                  </StyledDropdown>
                </StyledTableCell>
              </StyledTableRow>
            )
          }
          return (
            <HoverTableRow key={item.id}>
              <StyledLeftActionCell>
                <LeftActions>
                  <LeftAction
                    icon={<FiChevronUp size="24px" color={theme?.colors.primary?.main} />}
                    tabIndex={-1}
                    disabled={idx === 0}
                    onClick={() => onMoveUp?.(idx)}
                    aria-label="Mover arriba"
                    title="Mover arriba"
                  />

                  <LeftAction
                    icon={<FiChevronDown size="24px" color={theme?.colors.primary?.main} />}
                    tabIndex={-1}
                    disabled={isLastItem}
                    onClick={() => onMoveDown?.(idx)}
                    aria-label="Mover abajo"
                    title="Mover abajo"
                  />
                </LeftActions>
              </StyledLeftActionCell>

              <TableCell>
                <Text variant="black" size="sm">
                  {item.Descripcion}
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" size="sm">
                  {item.Unidades}
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" size="sm">
                  {item.CosteUnidad}
                </Text>
              </TableCell>

              <TableCell>
                <Text variant="black" size="sm">
                  {item.TotalPropuesta}
                </Text>
              </TableCell>

              <TableCell>
                <StyledDropdown>
                  <StyledDropdownOption onClick={() => onAdd?.({ initialData: item, isEditionMode: true, idx })}>
                    <AiOutlineEdit color={theme?.colors?.ntrl_dark?.main} size={20} />
                    Editar
                  </StyledDropdownOption>
                  <StyledDropdownOption onClick={() => onAdd?.({ initialData: { ...item } })}>
                    <AiOutlineCopy color={theme?.colors?.ntrl_dark?.main} size={20} />
                    Duplicar
                  </StyledDropdownOption>
                  <StyledDropdownOption isDangerAction onClick={() => onRemove?.(idx)}>
                    <AiOutlineDelete color={theme?.colors?.negative?.main} size={20} />
                    Eliminar
                  </StyledDropdownOption>
                </StyledDropdown>
              </TableCell>
            </HoverTableRow>
          )
        })}
        {renderTotalRow(rows)}
        <TableRow>
          {!isClosed && (
            <TableCell colSpan="9">
              <AddButton type="button" variant="transparent" onClick={onAdd}>
                <AiOutlineFolderAdd size={20} color={theme?.colors?.secondary?.main} />
                Añadir valoración
              </AddButton>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </StyledTable>
  )
}
