import { useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Input } from '../../../../../../../components/atoms/input/Input'
import { Select } from '../../../../../../../components/atoms/select/Select'
import { useReportCapitales, useStoredReport } from '../../../../../../../modules/reports/hooks/useReports'
import { useTarifas } from '../../../../../../../modules/tarifas/hooks/useTarifas'
import Utils from '../../../../../../../services/Utils'
import Actions from '../../../components/actions/Actions'

const Content = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  overflow: visible;
`

const ShortInput = styled(Input)`
  width: 160px;
`

const StyledShortSelect = styled(Select)`
  width: 240px;
`

const StyledSelect = styled(Select)`
  width: 550px;
`

const DescriptionInput = styled(Input)`
  width: 550px;
`

export const AddValoracionDanos = ({ onSubmit, onGoBack }) => {
  const location = useLocation()
  const valoracion = location.state?.initialData ?? {}
  const isEditionMode = location.state?.isEditionMode ?? false
  const initialRender = useRef(true)
  const { reportId } = useParams()
  const { data: report } = useStoredReport(reportId)
  const { data: capitales } = useReportCapitales(reportId)
  const { data: tarifas } = useTarifas()
  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      Id: valoracion?.Id ?? '',
      Zona: valoracion?.Zona ?? 'zona_a',
      Descripcion: valoracion?.Descripcion ?? '',
      RepInd: valoracion?.RepInd ?? '',
      TipoDanio: valoracion?.TipoDanio ?? 'propio',
      Unidades: valoracion?.Unidades ?? 0,
      CosteUnidad: valoracion?.CosteUnidad ?? 0,
      ValorNuevo: valoracion?.ValorNuevo ?? 0,
      Depreciacion: valoracion?.Depreciacion ?? 0,
      ValorReal: valoracion?.ValorReal ?? 0,
      TotalPropuesta: valoracion?.TotalPropuesta ?? 0,
      Capital: valoracion?.Capital ?? '',
      Perjudicado: valoracion?.Perjudicado ?? '',
    },
    mode: 'onBlur',
  })
  const watchEpigrafe = watch('Id')
  const watchCosteUnidad = watch('CosteUnidad')
  const watchUnidades = watch('Unidades')
  const watchDepreciacion = watch('Depreciacion')
  const watchZona = watch('Zona')
  const watchTipoDanio = watch('TipoDanio')

  const tarfiasOptions = useMemo(() => {
    return (tarifas ?? []).map((tarifa) => {
      return {
        label: `${tarifa.epigrafe} - ${tarifa.descripcion}`,
        value: tarifa.id,
      }
    })
  }, [tarifas])

  const perjudicadosOptions = useMemo(() => {
    return (report?.perjudicados ?? []).map((perjudicado) => {
      return {
        label: perjudicado.perjudicados_nombre,
        value: perjudicado.perjudicados_id,
      }
    })
  }, [report])

  useEffect(() => {
    if (!watchEpigrafe) {
      return
    }
    const tarifa = tarifas.find(({ id }) => id === watchEpigrafe)
    if (!tarifa) {
      return
    }

    setValue('Descripcion', tarifa.descripcion, {
      shouldValidate: true,
      shouldDirty: true,
    })

    if (!watchZona) {
      return
    }

    let sanitizedCosteUnidad = parseFloat(tarifa?.[watchZona?.toLowerCase()])
    if (isNaN(sanitizedCosteUnidad)) {
      sanitizedCosteUnidad = 0
    }
    const costeUnidad = Utils.toDinero(sanitizedCosteUnidad ?? 0)
    setValue('CosteUnidad', costeUnidad.convertPrecision(2).toUnit(), {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [setValue, tarifas, watchEpigrafe, watchZona])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    let costeUnidad
    try {
      costeUnidad = Utils.toDinero(parseFloat(watchCosteUnidad))
    } catch (error) {
      costeUnidad = Utils.toDinero(0)
    }

    const Unidades = isNaN(parseFloat(watchUnidades)) ? 0 : parseFloat(watchUnidades)
    let ValorNuevo = costeUnidad.multiply(Unidades)
    if (isNaN(ValorNuevo.convertPrecision(2).toUnit())) {
      ValorNuevo = Utils.toDinero(0)
    }
    let Depreciacion = isNaN(parseFloat(watchDepreciacion)) ? 0 : parseFloat(watchDepreciacion)
    let ValorReal = ValorNuevo.multiply(1 - Depreciacion / 100)
    if (isNaN(ValorReal.convertPrecision(2).toUnit())) {
      ValorReal = Utils.toDinero(0)
    }
    setValue('ValorNuevo', ValorNuevo.convertPrecision(2).toUnit(), {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue('ValorReal', ValorReal.convertPrecision(2).toUnit(), {
      shouldValidate: true,
      shouldDirty: true,
    })
    setValue('TotalPropuesta', ValorReal.convertPrecision(2).toUnit(), {
      shouldValidate: true,
      shouldDirty: true,
    })
  }, [setValue, watchCosteUnidad, watchUnidades, watchDepreciacion])

  useEffect(() => {
    if (watchTipoDanio !== 'perjudicados') {
      setValue('Perjudicado', '', {
        shouldValidate: true,
        shouldDirty: true,
      })
    }
  }, [setValue, watchTipoDanio])

  return (
    <Content onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="Capital"
        control={control}
        render={({ field }) => {
          return (
            <StyledShortSelect
              label="Capital"
              name="Capital"
              options={Object.keys(capitales ?? {}).map((capital) => {
                return {
                  label: capital,
                  value: capital,
                }
              })}
              required
              {...field}
            />
          )
        }}
      />

      <Controller
        render={({ field }) => (
          <StyledShortSelect
            label="Daño..."
            options={[
              {
                label: 'Reparable',
                value: 'reparable',
              },
              {
                label: 'Indemnizable',
                value: 'indemnizable',
              },
            ]}
            required
            {...field}
          />
        )}
        name="RepInd"
        control={control}
        defaultValue=""
      />

      <Controller
        render={({ field }) => (
          <StyledShortSelect
            label="Tipo de daño"
            options={[
              {
                label: 'Daño propio',
                value: 'propio',
              },
              {
                label: 'Daño estético',
                value: 'estetico',
              },
              {
                label: 'Daño a perjudicados',
                value: 'perjudicados',
              },
            ]}
            {...field}
          />
        )}
        name="TipoDanio"
        control={control}
        required
        defaultValue=""
      />

      {watchTipoDanio === 'perjudicados' ? (
        <Controller
          render={({ field }) => <StyledShortSelect label="Perjudicado" options={perjudicadosOptions} {...field} />}
          name="Perjudicado"
          control={control}
          defaultValue=""
        />
      ) : null}

      <Controller
        render={({ field }) => (
          <StyledShortSelect
            label="Zona..."
            options={[
              {
                label: 'Zona A',
                value: 'zona_a',
              },
              {
                label: 'Zona B',
                value: 'zona_b',
              },
              {
                label: 'Gremio A',
                value: 'gremios_a',
              },
              {
                label: 'Gremio B',
                value: 'gremios_b',
              },
            ]}
            {...field}
          />
        )}
        name="Zona"
        control={control}
        defaultValue="A"
      />

      <Controller
        render={({ field }) => <StyledSelect label="Epígrafe" options={tarfiasOptions} {...field} />}
        name="Id"
        control={control}
        defaultValue=""
      />
      <DescriptionInput
        {...register('Descripcion', { required: true })}
        label="Descripcion"
        placeholder="Escribe aquí"
        type="text"
      />

      <ShortInput
        {...register('Unidades', { required: true })}
        label="UD."
        placeholder="Escribe aquí"
        required
        type="number"
      />
      <ShortInput
        {...register('CosteUnidad', { required: true })}
        label="Coste Ud."
        placeholder="Escribe aquí"
        required
        type="number"
      />
      <ShortInput {...register('ValorNuevo')} label="Valor Nuevo" type="number" disabled />
      <ShortInput {...register('Depreciacion')} label="Depreciación %" placeholder="Escribe aquí" type="number" />
      <ShortInput {...register('ValorReal')} label="Valor Real" type="number" disabled />
      <ShortInput
        {...register('TotalPropuesta', { required: true })}
        label="Total"
        placeholder="Escribe aquí"
        required
        type="number"
      />

      <Actions
        isSubmitting={isSubmitting}
        nextDisabled={!isValid}
        nextLabel={isEditionMode ? 'Guardar' : 'Añadir'}
        onBackClick={onGoBack}
      />
    </Content>
  )
}
