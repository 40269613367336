import url from 'url'
import RequestHandler from '../request'
import { toast } from 'react-toastify'

const AUTOS_CATEGORY = 5

const EXP_NUM_REGEX = /-(?<reportNumber>\d)+/g

export const getStoredFileUrl = ({ reportId, type = 'photos', fileId }) => {
  const { getAuthToken } = RequestHandler.sharedInstance()
  return `${url.resolve(
    window.MontesPeritos.config.REACT_APP_BASE_HOST,
    `expedientes/${reportId}/anexos/${type}/${fileId}`,
  )}?access_token=${getAuthToken()}`
}

export const sanitizeFloatValue = (value, defaultValue = 0) => {
  if (!value || isNaN(parseFloat(value))) {
    return defaultValue
  }
  return parseFloat(value)
}

export const showBlockedReportdWarning = function () {
  toast.error('Este informe está bloqueado y no se actualizarán los cambios que efectues.')
}

export const getUsableRelatedReports = function ({ relatedReports }) {
  if (!relatedReports || relatedReports.length === 0) {
    return []
  }

  return relatedReports.filter(
    (relatedReport) => relatedReport.categoria_id !== AUTOS_CATEGORY && !!relatedReport.hasReportData,
  )
}

export const hasNewerReports = function ({ report, relatedReports }) {
  if (!relatedReports || relatedReports.length === 0) {
    return false
  }

  // We don't want to take into account Autos reports nor -pte reports
  const sanitizedRelatedReports = relatedReports.filter(
    (relatedReport) => relatedReport.categoria_id !== AUTOS_CATEGORY,
  )

  const reportNumRegex = new RegExp(EXP_NUM_REGEX)
  const reportNumMatches = reportNumRegex.exec(report?.numero_siniestro ?? '')
  let reportNum

  if (reportNumMatches?.length > 0) {
    reportNum = Number(
      reportNumMatches.groups ? reportNumMatches.groups.reportNumber : reportNumMatches[1] ?? reportNumMatches[0],
    )
  }

  if (isNaN(reportNum)) {
    reportNum = 0
  }

  if (reportNum === 0 && sanitizedRelatedReports.length > 0) {
    return true
  }

  return sanitizedRelatedReports.some((report) => {
    const relatedReportNumRegex = new RegExp(EXP_NUM_REGEX)
    const relatedReportNumMatches = relatedReportNumRegex.exec(report?.numero_siniestro ?? '')
    let relatedReportNum

    if (relatedReportNumMatches?.length > 0) {
      relatedReportNum = Number(
        reportNumMatches.groups ? reportNumMatches.groups.reportNumber : reportNumMatches[1] ?? reportNumMatches[0],
      )
    }

    if (isNaN(relatedReportNum)) {
      return false
    }
    return relatedReportNum > reportNum
  })
}
