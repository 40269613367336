import React from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { Button, Text } from '../../../../components'
import Card from '../../../../components/atoms/card/Card'
import { Radio } from '../../../../components/atoms/radio/Radio'
import { useSaveReport } from '../../../../modules/reports/hooks/useReports'
import { routes } from '../../../../services/Routing'
import Utils from '../../../../services/Utils'
import { Headline } from '../components/headline'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Options = styled.section`
  display: grid;
  gap: 12px;
`

const OptionCard = styled(Card)`
  position: relative;
  padding: 12px;
`

const StyledRadio = styled(Radio)`
  position: absolute;
  top: 15px;
  left: 12px;
`

const OptionCardTitle = styled(Card.Title)`
  margin-left: 30px;
`

const Actions = styled.nav`
  margin-top: 24px;
  display: flex;
  gap: 12px;
`

export const SelectTipoExpedienteScreen = React.memo(() => {
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { register, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      tipoExpediente: '',
    },
    mode: 'onBlur',
  })
  const handleSaveReport = useSaveReport()

  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      handleSaveReport({
        data: values,
        onSuccess: () => {
          resolve()
          navigate(generatePath(routes.informacionGeneral, { reportId }))
        },
        onError: () => {
          toast.error('Ha ocurrido un error al editar el expediente')
          reject()
        },
      })
    })
  }

  const { isSubmitting } = formState
  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          ¿Qué tipo de expediente quieres rellenar?
        </Headline>
        <Text variant="black">Esta elección es permanente y no podrás cambiarla más adelante</Text>
      </Header>

      <Options>
        <OptionCard
          onClick={() => {
            setValue('tipoExpediente', Utils.TIPO_EXPEDIENTE.DANOS)
          }}
        >
          <StyledRadio value={Utils.TIPO_EXPEDIENTE.DANOS} {...register('tipoExpediente')} />
          <OptionCardTitle>Informe de daños</OptionCardTitle>
        </OptionCard>

        <OptionCard
          onClick={() => {
            setValue('tipoExpediente', Utils.TIPO_EXPEDIENTE.DEF_JURIDICA)
          }}
        >
          <StyledRadio value={Utils.TIPO_EXPEDIENTE.DEF_JURIDICA} {...register('tipoExpediente')} />
          <OptionCardTitle>Informe de defensa jurídica</OptionCardTitle>
        </OptionCard>
      </Options>

      <Actions>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Guardando...' : 'Seleccionar'}
        </Button>
      </Actions>
    </Container>
  )
})
