import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import { Editor, Text } from '../../../../../../components'
import { Comments } from '../../../../../../components/molecules/comments/Comments'
import { Headline } from '../../../components/headline'
import Actions from '../../components/actions/Actions'

const Container = styled.form`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
`

const ItalicText = styled(Text)`
  font-style: italic;
  margin-bottom: 12px;
`

export const DanosForm = ({ report, step, onFormChange, onGoBack, onSubmit }) => {
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      descripcionSiniestro_OrigenYCircunstancias: report?.descripcionSiniestro_OrigenYCircunstancias ?? '',
      descripcionSiniestro_DaniosReclamados: report?.descripcionSiniestro_DaniosReclamados ?? '',
      descripcionSiniestro_ConsideracionesPericiales: report?.descripcionSiniestro_ConsideracionesPericiales ?? '',
      descripcionSiniestro_Conclusiones: report?.descripcionSiniestro_Conclusiones ?? '',
      descripcionSiniestro_DescripcionRiesgo: report?.descripcionSiniestro_DescripcionRiesgo ?? '',
    },
    mode: 'onBlur',
  })

  const handleFormChange = () => {
    onFormChange?.({ values: getValues() })
  }

  const handleGoBack = useCallback(() => {
    onGoBack?.({ values: getValues() })
  }, [onGoBack, getValues])

  const {
    descripcionSiniestro_OrigenYCircunstancias,
    descripcionSiniestro_DaniosReclamados,
    descripcionSiniestro_DescripcionRiesgo,
    descripcionSiniestro_ConsideracionesPericiales,
    descripcionSiniestro_Conclusiones,
  } = getValues()

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <Header>
        <Headline size="l" weight="500" variant="black">
          Descripción del siniestro
        </Headline>
      </Header>

      <Box>
        <Text variant="black" weight="600">
          Descripción del riesgo
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_DescripcionRiesgo}
          onChange={(value) => setValue('descripcionSiniestro_DescripcionRiesgo', value)}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Descripción del siniestro
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_OrigenYCircunstancias}
          onChange={(value) => {
            setValue('descripcionSiniestro_OrigenYCircunstancias', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Daños reclamados
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_DaniosReclamados}
          onChange={(value) => {
            setValue('descripcionSiniestro_DaniosReclamados', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Consideraciones periciales
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_ConsideracionesPericiales}
          onChange={(value) => {
            setValue('descripcionSiniestro_ConsideracionesPericiales', value, {
              shouldValidate: true,
              shouldDirty: true,
            })
            handleFormChange()
          }}
        />
      </Box>

      <Box>
        <Text variant="black" weight="600">
          Conclusiones
        </Text>
        <ItalicText variant="black" weight="400" size="sm">
          Si no añades ningún texto, no aparecerá en el informe final
        </ItalicText>
        <Editor
          value={descripcionSiniestro_Conclusiones}
          onChange={(value) => {
            setValue('descripcionSiniestro_Conclusiones', value, { shouldValidate: true, shouldDirty: true })
            handleFormChange()
          }}
        />
      </Box>

      <Actions isSubmitting={isSubmitting} onBackClick={handleGoBack} />

      <Comments step={step} />
    </Container>
  )
}
