import React, { useMemo, useState } from 'react'
import { HiClipboardList, HiDownload } from 'react-icons/hi'
import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { Button, ButtonIcon, Icon, Modal, Text } from '../../../../../../../../components'
import { withDownloadModal } from '../../../../../../../../hooks/useDownload'
import { useIsDevMode } from '../../../../../../../../hooks/useIsDevMode'
import { useModal } from '../../../../../../../../hooks/useModal'
import {
  useMontesPeritosExpedienteName,
  useRelatedReports,
} from '../../../../../../../../modules/reports/hooks/useReports'
import { DEVICE_SIZE } from '../../../../../../../../theme/theme'
import { SidebarLink } from '../sidebarLink/SidebarLink'
import { RelatedReportsModal } from './components/relatedReportsModal/RelatedReportsModal'
import { routes } from '../../../../../../../../services/Routing'

const Container = styled.div`
  background-color: ${({ theme }) => theme?.colors?.ntrl_lighter?.main};
  width: 100%;
  max-width: 322px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  ${() => {
    if (window.MontesPeritos?.config?.isDEVModeEnabled) {
      return css`
        & :is(a, p) {
          color: white !important;
        }
        background-color: ${({ theme }) => theme?.colors?.primary?.main};
      `
    }
  }}

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    max-width: unset;
    height: auto;
  }
`

const Content = styled.nav`
  padding: 24px 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    transition: transform 0.3s ease-in;
    transform: ${({ opened }) => (opened ? 'translateX(0%)' : 'translateX(-100%)')};
    background-color: ${({ theme }) =>
      window.MontesPeritos?.config?.isDEVModeEnabled
        ? theme?.colors?.primary?.main
        : theme?.colors?.ntrl_lighter?.main};
    padding: 24px 56px;
  }
`

const CloseButton = styled(ButtonIcon)``

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    margin-left: -46px;
  }

  ${CloseButton} {
    display: none;
  }

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    ${CloseButton} {
      display: block;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  padding: 0;
`

const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
`

const Breadcrumb = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  flex-shrink: 0;

  @media (max-width: ${DEVICE_SIZE.TABLET}) {
    display: flex;
  }
`

const MenuWrapper = styled.div`
  position: relative;
  display: flex;

  &:before {
    content: '';
    display: ${({ hasNotifications }) => (hasNotifications ? 'block' : 'none')};
    position: absolute;
    top: 2px;
    right: 2px;
    width: 8px;
    height: 8px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 4px;
    border: ${({ theme }) => `solid 2px ${theme?.colors?.ntrl_lighter?.main}`};
    background-color: ${({ theme }) => theme?.colors?.primary?.main};
  }
`

const SidebarLinkIcon = styled(Icon)`
  margin-left: 3px;
`

const StyledLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme?.colors?.secondary?.main};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
`

const HeaderInfo = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`

const BetaTag = styled.span`
  background-color: ${({ theme }) => theme?.colors?.negative?.main};
  padding: 3px 6px;
  border-radius: 3px;
  color: white;
  font-weight: 600;
  align-self: flex-start;
`

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
`

export const Sidebar = withDownloadModal(
  React.memo(({ items, handleDownload, ...props }) => {
    const location = useLocation()
    const [opened, setOpened] = useState(false)
    const { reportId } = useParams()
    const { data: relatedReports } = useRelatedReports(reportId)
    const [{ isOpen }, { showModal, closeModal }] = useModal()
    const isDEVMode = useIsDevMode()
    const headline = useMontesPeritosExpedienteName()
    const navigate = useNavigate()

    const handleOpenSidebar = () => {
      setOpened(true)
    }

    const handleCloseSidebar = () => {
      setOpened(false)
    }

    const activePath = useMemo(() => {
      return items.find(({ path }) => matchPath({ path, end: true }, location?.pathname))?.label ?? ''
    }, [location, items])

    const hasNotifications = useMemo(() => {
      return items.some(({ notifications }) => notifications && notifications > 0)
    }, [items])

    const handleOpenRelatedReports = () => {
      showModal()
    }

    const handleSeeDetails = () => {
      navigate(generatePath(routes.reportDetails, { reportId }))
    }

    const handleSeePreview = () => {
      navigate(generatePath(routes.reportPreview, { reportId }))
    }

    const countLabel = React.useMemo(() => {
      if (!relatedReports) {
        return ''
      }

      const count = relatedReports?.length ?? 0

      if (count === 0) {
        return ''
      }

      if (count === 1) {
        return '1 expediente relacionado'
      }

      return `${count} expedientes relacionados`
    }, [relatedReports])

    return (
      <Container {...props}>
        <Breadcrumb>
          <ButtonIcon
            variant="transparent"
            icon={
              <MenuWrapper hasNotifications={hasNotifications}>
                <Icon name="menu" />
              </MenuWrapper>
            }
            onClick={handleOpenSidebar}
          />

          <Text size="m" weight="regular" variant="ntrl_dark">
            {headline} /{' '}
            <Text size="m" weight="medium" variant="ntrl_darkt" as="span">
              {activePath}
            </Text>
          </Text>
        </Breadcrumb>
        <Content opened={opened}>
          <ContentHeader>
            <CloseButton variant="transparent" icon={<Icon name="close" />} onClick={handleCloseSidebar} />

            <HeaderInfo>
              {isDEVMode ? <BetaTag>BETA</BetaTag> : null}

              <Text size="xl" weight="light" variant="ntrl_dark">
                {headline}
              </Text>

              <StyledLink onClick={handleSeeDetails}>Ver detalles</StyledLink>
              <StyledLink onClick={handleOpenRelatedReports}>{countLabel}</StyledLink>
            </HeaderInfo>
          </ContentHeader>

          <List>
            {items.map((item) => {
              const isActive = matchPath({ path: item.path, end: true }, location?.pathname)

              return (
                <SidebarLink
                  key={item.path}
                  to={item.path}
                  count={item.notifications}
                  isActive={!!isActive}
                  external={item.external}
                  onClick={handleCloseSidebar}
                >
                  {item.label}
                  {item.icon && <SidebarLinkIcon name={item.icon} />}
                </SidebarLink>
              )
            })}
          </List>

          <Actions>
            <StyledButton variant={isDEVMode ? 'white' : 'primary'} bordered onClick={handleSeePreview}>
              <HiClipboardList size={18} />
              Ver informe
            </StyledButton>

            <StyledButton variant={isDEVMode ? 'white' : 'primary'} onClick={handleDownload}>
              <HiDownload size={18} />
              Descargar
            </StyledButton>
          </Actions>
        </Content>

        <Modal isOpen={isOpen} onRequestClose={closeModal}>
          <RelatedReportsModal onClose={closeModal} />
        </Modal>
      </Container>
    )
  }),
)

Sidebar.defaultProps = {
  items: [],
}

Sidebar.displayName = 'Sidebar'
